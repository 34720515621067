import React from "react";
import { Field } from "formik";
import styled from "styled-components";
import withFieldProps from "./withFieldProps";
import { FieldWrap, Label, FieldError } from "./Styles";

export const StyledTextarea = styled.textarea`
  padding: 0.5rem 0.75rem;
  border: 1px solid #ccc;
  width: 100%;
  font-size: 1rem;
  margin: 0.5rem 0;
`;

const TextField = ({ name, label, required, error, field, rows = 5, ...props }) => {
  return (
    <FieldWrap>
      <Label required={required}>{label}</Label>
      <StyledTextarea id={name} rows={rows} {...field} {...props} />
      {error ? <FieldError>{error}</FieldError> : null}
    </FieldWrap>
  );
};

const EnhancedText = withFieldProps(TextField);
const Text = (props) => <Field {...props} component={EnhancedText} />;
export default Text;
