import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Flex, Box } from "grid-styled";
import styled from "styled-components";
import FA from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/fontawesome-free-solid";
import { Helmet } from "react-helmet";
import calculateAvailableOptions from "../../calculateAvailableOptions";
import { PageContainer, Row, Column } from "../Grid";
import { H1, H2, DescriptionText } from "../TextStyles";
import { LargeButton } from "../Button";
import seriesImages from "./seriesImages";
import Breadcrumb from "../Breadcrumb";

const getSeriesImg = (_series) => {
  let series;

  series = _series.replace(":", "_");
  if (_series === "2A") series = "TWOA";
  return seriesImages[series];
};

const SeriesImg = styled.div`
  width: 100%;
  text-align: center;
  height: 150px;
`;
const InnerSeriesWrap = styled(Flex)`
  height: 100%;
  align-content: flex-start;
`;

const SeriesDisplay = ({ code, description, name }) => {
  const location = useLocation();
  return (
    <Column flex="0 1 auto" width={[1, 1 / 2, 1 / 3, 1 / 4]} key={code}>
      <InnerSeriesWrap flexWrap="wrap" flex="1 1 auto" flexDirection="column">
        <Box width={1}>
          <H2>{name}</H2>
        </Box>
        <SeriesImg>
          <img src={getSeriesImg(code)} alt={name} />
        </SeriesImg>

        <DescriptionText my="1rem">{description}</DescriptionText>
        <Box mt="auto" width={1}>
          <Link to={{ pathname: "/build", state: { ...location.state, series: code } }}>
            <LargeButton>Select {name}</LargeButton>
          </Link>
        </Box>
      </InnerSeriesWrap>
    </Column>
  );
};

const SelectSeries = () => {
  const location = useLocation();
  const { market, style, upholstered } = location.state || {};
  const { options } = calculateAvailableOptions({ market, style, upholstered });
  const { data: seriesData } = options.find(({ type }) => {
    return type === "series";
  });
  const series = seriesData.filter(({ code }) => code !== "X");
  return (
    <PageContainer>
      <Helmet>
        <title>Know Your Series?</title>
      </Helmet>
      <Breadcrumb />

      <H1 as="h2">Don’t Know Your Series?</H1>

      <Box width={1} mb="1rem">
        If you don’t know your series, its not a problem, just click{" "}
        <strong>Build Your Chair</strong> below.
      </Box>

      <Box width={[1, "300px"]}>
        <Link to={{ pathname: "/build", state: { ...location.state } }}>
          <LargeButton>
            {" "}
            <FA icon={faCog} /> Build Your Chair
          </LargeButton>
        </Link>
      </Box>

      <H1>Know Your Series?</H1>

      <Box width={1} mb="1rem">
        If you know your series, please select it here. This will auto-populate the correct
        configurations offered as a baseline. From there you will be able to customize/modify with
        any available items from within the available options.
      </Box>
      <Row flexWrap="wrap">{series.map(SeriesDisplay)}</Row>
    </PageContainer>
  );
};

export default SelectSeries;
