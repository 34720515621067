import React, { createContext, useEffect, useState } from "react";
import { sign } from "jsonwebtoken";
import APIClient from "../../apiClient";

const privateKey = "STAGING_ySmhslp8UqTKblFZ0b6OCX7sELKhqd10FVkBSxPBoik";
const localStorageKey = "pricingToken";

const apiClient = new APIClient();

export const PricingContext = createContext();

const generateToken = (payload = {}) => {
  if (process.env.REACT_APP_STAGE === "production") return false;
  const token = sign(payload, privateKey, {
    algorithm: "HS256",
    expiresIn: "16 hours",
    audience: "mychair.biofit.com",
    issuer: "mychair.biofit.com",
  });

  return token;
};

const verifyToken = async (token) => {
  if (!token) return false;

  try {
    await apiClient.fetch(process.env.REACT_APP_BIOFIT_VERIFY_PRICING_URL, {
      fullUrl: true,
      body: { token },
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const PricingProvider = ({ children }) => {
  const token = localStorage.getItem(localStorageKey);
  const [pricingEnabled, setPricingEnabled] = useState(false);

  useEffect(() => {
    const callVerify = async () => {
      const validToken = await verifyToken(token);
      if (validToken) {
        setPricingEnabled(true);
      } else {
        localStorage.removeItem(localStorageKey);
      }
    };
    callVerify();
  }, [token]);

  // if (token && !tokenFromLocalStorageIsValid) {
  //   // cleanup localStorage if there is an invalid token stored
  //   localStorage.removeItem(localStorageKey);
  // }

  const validateAndStoreToken = async (token) => {
    if (!(await verifyToken(token))) return false;
    localStorage.setItem(localStorageKey, token);
    setPricingEnabled(true);
  };

  const disablePricing = () => {
    localStorage.removeItem(localStorageKey);
    setPricingEnabled(false);
  };

  return (
    <PricingContext.Provider
      value={{ validateAndStoreToken, generateToken, disablePricing, pricingEnabled }}
    >
      {children}
    </PricingContext.Provider>
  );
};
