import ALL from "./ALL";

export default [
  {
    code: "RC",
    name: "RC - Resistance Caster",
    description: `Two inches in height, with dual soft-wheels. Casters resist movement when the seat is unoccupied.
      Plastic collar around caster stem to match base. Standard casters have grey tread.`,
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "U", "G", "W", "K", "T", "C", "R", "VF:R"],
    back: ALL,
    base: ["TUH", "A", "S", "R", "W"],
    height: ALL,
    control: ["PM", "E3", "T", "FFAC", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD", "P", "S", "Z"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    performance: [
      "ISO6",
      "ISO5",
      "ISO4",
      "ISO3",
      "C",
      "06",
      "ISO8",
      "ISO7",
      "WD",
      "ISO8C",
      "ISO7C",
    ],
    memoryFoam: ALL,
    series: ALL,
    price: 67,
  },
  {
    code: "RK",
    name: "RK - ESD Resistance Caster",
    description: `Two inches in height, with dual soft-wheels. Casters resist movement when the seat is unoccupied.
      Plastic collar around caster stem to match base. Black tread indicates an ESD caster.`,
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "U", "G", "T", "C", "R"],
    back: ["MB", "TB", "E", "M", "T", "C", "L", "U", "G", "X", "B"],
    base: ["TUH", "A", "S", "W"],
    height: ALL,
    control: ["PM", "E3", "T", "FFAC", "EXE", "S", "T:SLD", "FFAC:SLD", "EXE:SLD"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    performance: ["K", "ISO6K", "ISO5K", "ISO4K", "ISO3K", "ISO8K", "ISO7K", "WDK"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    price: 67,
  },
  {
    code: "HG",
    name: "HG - High Glides",
    description: `2.25" high black glides have a base diameter of 2.125" and are made of first class polyamide with a
      steel grip ring stem. ESD version is indicated by a yellow ring around the stem of the glide.`,
    seat: [
      "1",
      "1F",
      "1B",
      "A",
      "B",
      "E",
      "F",
      "H",
      "U",
      "T",
      "G",
      "W",
      "K",
      "C",
      "R",
      "VF:R",
      "L",
    ],
    back: ALL,
    base: ["TUH", "A", "S", "R", "W", "P"],
    height: ALL,
    control: [
      "PM",
      "E3",
      "T",
      "FFAC",
      "EXE",
      "T:SLD",
      "FFAC:SLD",
      "EXE:SLD",
      "P",
      "S",
      "Z",
      "X",
      "L",
      "IP",
    ],
    footring: ALL,
    arm: ALL,
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    price: 67,
  },
  {
    code: "GL",
    name: "GL - Standard",
    description: "",
    seat: ["TX", "T"],
    back: ["X"],
    base: ["G", "A18", "A24", "A30"],
    height: ALL,
    control: ["S", "X"],
    footring: ["XF"],
    arm: ["XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["AJ", "TXG"],
    price: 54,
  },
  {
    code: "CRC",
    name: "CRC - Dual-wheel Casters",
    description: "Dual-wheel casters designed for hard floors.",
    seat: ["R", "VF:R"],
    back: ["X"],
    base: ["C"],
    height: ["L", "M"],
    control: ["FA"],
    footring: ["XF"],
    arm: ["XA"],
    performance: ["C"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    price: 65,
  },
  {
    code: "IPR",
    name: 'IPR - Heavy Duty 3" diameter',
    description: `Sturdy 3" diameter dual-wheel casters that are load rated to 165 lbs. each.`,
    seat: ["F"],
    back: ["S"],
    base: ["P"],
    height: ["M"],
    control: ["IP"],
    footring: ["XF"],
    arm: ["ISAS", "XA"],
    performance: ["06"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
  },
];
