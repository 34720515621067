import React from "react";
import styled from "styled-components";
import getPrice from "../Pricing/getPrice";
import usePricing from "../Pricing/usePricing";

const PriceStyle = styled.div`
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
`;

const Price = ({ selected }) => {
  const { pricingEnabled } = usePricing();

  if (!pricingEnabled) return null;
  const price = getPrice(selected);

  return <PriceStyle>Total Cost: ${price}</PriceStyle>;
};

export default Price;
