const scepter = ({ selected, options }) => {
  let filteredOptions = options;
  // RULE: Scepter (ASR) must have arms (PSAS)

  const { back, base } = selected || {};

  if (back === "S" && base !== "P") {
    filteredOptions = filteredOptions.map(({ type, data, ...rest }) => {
      // force PSAS or ARS arms
      if (type === "arm") {
        data = data.filter(({ code }) => ["ARS", "PSAS"].indexOf(code) !== -1);
      }
      return {
        type,
        data,
        ...rest,
      };
    });
  }

  return { selected, options: filteredOptions };
};

export default scepter;
