const TULHeight = ({ selected, options: initialOptions }) => {
  let options = initialOptions;
  // RULE: If TUL base (S) is selected (within MVMT) remove high seat height (H)

  const { seat, base, height } = selected || {};

  const isMVMT = ["1", "1F", "1B"].indexOf(seat) !== -1;

  if (isMVMT && base === "S") {
    options = options.map(({ type, data, ...rest }) => {
      // don't allow H height
      if (type === "height") {
        data = data.filter(({ code }) => code !== "H");
      }
      return {
        type,
        data,
        ...rest,
      };
    });
  }

  if (isMVMT && height === "H") {
    options = options.map(({ type, data, ...rest }) => {
      // don't allow S base
      if (type === "base") {
        data = data.filter(({ code }) => code !== "S");
      }
      return {
        type,
        data,
        ...rest,
      };
    });
  }

  return { selected, options };
};

export default TULHeight;
