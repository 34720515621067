import React from "react";
import usePricing from "./usePricing";
import Button from "../Button";

const EnablePricingFooter = () => {
  const { generateToken, validateAndStoreToken, disablePricing, pricingEnabled } = usePricing();

  const enablePricing = () => {
    const token = generateToken();
    validateAndStoreToken(token);
  };

  if (process.env.REACT_APP_STAGE === "production") return null;

  return (
    <div style={{ marginTop: "4rem" }}>
      {pricingEnabled ? (
        <Button onClick={disablePricing}>Disable Pricing</Button>
      ) : (
        <Button onClick={enablePricing}>Enable Pricing</Button>
      )}

      <div>
        The Enable / Disable Pricing button is only present on staging, for testing purposes.
      </div>
    </div>
  );
};

export default EnablePricingFooter;
