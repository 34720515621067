/* globals gtag */
import React from "react";
import { withRouter } from "react-router-dom";

const googleAnalytics = process.env.REACT_APP_GOOGLE_ANALYTICS;

class GAHandlerHOC extends React.Component {
  componentDidMount() {
    if (!googleAnalytics) return;
    this.sendTracking();
  }
  componentDidUpdate(prevProps) {
    const { location = {} } = this.props;
    const { location: previousLocation = {} } = prevProps;
    if (location.pathname !== previousLocation.pathname) {
      this.sendTracking();
    }
  }
  sendTracking() {
    if (!googleAnalytics) return;
    const { location = {} } = this.props;
    // Send page to GA
    // gtag("config", googleAnalytics, { page_path: location.pathname });
    setTimeout(() => gtag("config", googleAnalytics, { page_path: location.pathname }), 500);
  }
  render() {
    return <div>{this.props.children}</div>;
  }
}
export default withRouter(GAHandlerHOC);
