import styled from "styled-components";
import { H2 } from "../TextStyles";

export const WarrantyImg = styled.img`
  padding: 1rem;
  max-width: 400px;
  width: 100%;
  min-height: 400px;
`;

export const FormLabel = H2.extend`
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.primary};
`;

export const ImgDisclaimer = styled.div`
  width: 100%;
  font-style: italic;
  font-size: 0.8rem;
  text-align: center;
  color: #6d6e70;
`;
