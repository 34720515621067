import ALL from "./ALL";

export default [
  {
    code: "X",
    name: "No Memory Foam",
    description: "",
    seat: ALL,
    back: ALL,
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ALL,
    vinylUnderwrap: ALL,
    series: ALL,
  },
  {
    code: "FM",
    name: "Memory Foam",
    description: "",
    seat: ["A", "E", "F", "H"],
    back: ALL,
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ALL,
    vinylUnderwrap: ALL,
    series: ALL,
    price: [
      {
        component: "seat",
        code: ["A", "E"],
        price: 55,
      },
      {
        component: "seat",
        code: ["F", "H"],
        price: 55,
      },
    ],
  },
];
