import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Flex, Box } from "grid-styled";
import { Helmet } from "react-helmet";
import { PageContainer, Row, Column } from "../Grid";
import { H1, H2, DescriptionText } from "../TextStyles";
import { LargeButton } from "../Button";
import Breadcrumb from "../Breadcrumb";

const Market = () => {
  const location = useLocation();
  const history = useHistory();
  if (location.state.market === "office") {
    history.replace("/series", { ...location.state, upholstered: "upholstered" });
  }
  return (
    <PageContainer>
      <Helmet>
        <title>Foundation</title>
      </Helmet>
      <Breadcrumb />
      <H1>Foundation</H1>
      <Flex flexWrap="wrap">
        <Box width={1} mb="1rem">
          Choose between and upholstered chair or non-upholstered chair.
        </Box>
        <Row flex="1" alignItems="start" flexWrap="wrap">
          <Column width={[1, 1, 1 / 3]}>
            <H2>Upholstered</H2>
            <DescriptionText my="1rem">
              Choose from a selection of upholstery options to meet your needs for style and
              performance.
            </DescriptionText>
            <Link
              to={{ pathname: "/series", state: { ...location.state, upholstered: "upholstered" } }}
            >
              <LargeButton>Select Upholstered</LargeButton>
            </Link>
          </Column>
          <Flex width={[1, 1, 1 / 3]} justifyContent="center" alignSelf="center">
            <H2>- Or -</H2>
          </Flex>
          <Column width={[1, 1, 1 / 3]}>
            <H2>Non-Upholstered</H2>
            <DescriptionText my="1rem">
              If upholstered seating does not meet your performance or style specifications, we have
              solutions.
            </DescriptionText>
            <Link
              to={{
                pathname: "/series",
                state: { ...location.state, upholstered: "nonUpholstered" },
              }}
            >
              <LargeButton>Select Non-Upholstered</LargeButton>
            </Link>
          </Column>
        </Row>
      </Flex>
    </PageContainer>
  );
};

export default Market;
