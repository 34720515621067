import React from "react";
import { Link } from "react-router-dom";
import { Flex, Box } from "grid-styled";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { PageContainer } from "../Grid";
import { H1, DescriptionText } from "../TextStyles";
import { LargeButton } from "../Button";
import seatComponents from "../../seatComponents";
import education from "./images/education.jpeg";
import industrial from "./images/industrial.jpeg";
import laboratory from "./images/laboratory.jpeg";
import healthcare from "./images/healthcare.jpeg";
import office from "./images/office.jpeg";
import cleanRoom from "./images/cleanRoom.jpg";
import staticControl from "./images/staticControl.jpg";
import cleanRoomStaticControl from "./images/cleanRoomStaticControl.jpg";
import Breadcrumb from "../Breadcrumb";

const [{ data: markets }] = seatComponents.filter(({ type }) => type === "market");

const images = {
  education,
  industrial,
  laboratory,
  healthcare,
  office,
  cleanRoom,
  staticControl,
  cleanRoomStaticControl,
};

const MarketImage = styled(Flex)`
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: 50% 30%;
  height: 250px;
  position: relative;
  &::after {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
const MarketName = styled.div`
  color: white;
  font-size: 1.7rem;
  line-height: 1.7rem;
  z-index: 1;
  font-weight: 400;
  text-align: center;
`;

const MarketComponent = ({ code, name, description }) => {
  return (
    <Box px={4} my={4} width={[1, 1 / 2, 1 / 3]} key={code}>
      <MarketImage alignItems="center" justifyContent="center" image={images[code]}>
        <MarketName>{name}</MarketName>
      </MarketImage>
      <DescriptionText my="1rem">{description}</DescriptionText>
      <Box mr={[0, 3, 6]}>
        <Link to={{ pathname: "/style", state: { market: code } }}>
          <LargeButton>Select Market</LargeButton>
        </Link>
      </Box>
    </Box>
  );
};

const Market = () => (
  <PageContainer>
    <Helmet>
      <title>Choose Your Market</title>
    </Helmet>
    <Breadcrumb />
    <H1>Choose Your Market</H1>
    <Flex flexWrap="wrap">
      <Box width={1} mb="1rem">
        Get fast, easy access to all the information you need on any chair in the extensive line of
        BioFit furnishings. Or, design your ideal seating option, built to your exact
        specifications.
      </Box>
      <Flex mx={-4} flexWrap="wrap">
        {markets.map(MarketComponent)}
      </Flex>
    </Flex>
  </PageContainer>
);

export default Market;
