import sld from "./sld";
import TULHeight from "./TULHeight";
import exe from "./exe";
import intensivePlus from "./intensivePlus";
import mvmtBaseHeight from "./mvmtBaseHeight";
import seatUArmControl from "./seatUArmControl";
import iso3Control from "./iso3Control";
import scepter from "./scepter";
import mvmtControls from "./mvmtControls";

const compoundRestrictions = ({
  selected: initialSelected = {},
  options: initialOptions = [],
  forceType = null,
}) => {
  let options = initialOptions;
  let selected = initialSelected;

  ({ selected, options } = sld({ selected, options, forceType }));
  ({ selected, options } = TULHeight({ selected, options, forceType }));
  ({ selected, options } = exe({ selected, options, forceType }));
  ({ selected, options } = intensivePlus({ selected, options, forceType }));
  ({ selected, options } = mvmtBaseHeight({ selected, options, forceType }));
  ({ selected, options } = seatUArmControl({ selected, options, forceType }));
  ({ selected, options } = iso3Control({ selected, options, forceType }));
  ({ selected, options } = scepter({ selected, options, forceType }));
  ({ selected, options } = mvmtControls({ selected, options, forceType }));

  return { selected, options };
};

export default compoundRestrictions;
