import calculateAvailableOptions from "./calculateAvailableOptions";

const selectionsAreValid = (selection) => {
  const { options: availableOptions = [] } = calculateAvailableOptions(selection) || {};

  const invalid =
    availableOptions.filter(({ type, data: optionData }) => {
      if (optionData.length === 0) return true;
      const options = optionData.map(({ code }) => code);
      if (!selection[type]) return false;
      if (options.indexOf(selection[type]) === -1) return true;

      return false;
    }).length > 0;

  return invalid !== true;
};

export default selectionsAreValid;
