import removeInvalidSelections from "../removeInvalidSelections";
import calculateAvailableOptions from "../calculateAvailableOptions";
import { selectFirstValidFromSelection } from "../manufactureCode";

const intensivePlus = ({ selected: initialSelected, options: initialOptions }) => {
  let options = initialOptions;
  let selected = initialSelected;
  // RULE: P base is the only option when F seat + S back

  const { seat, back, base } = selected || {};

  if (seat === "F" && back === "S" && base !== "P") {
    // Force P and re-check for available options.
    selected.base = "P";

    selected = removeInvalidSelections(selected, "base");
    selected = selectFirstValidFromSelection(selected);

    return calculateAvailableOptions(selected);
  }

  return { selected, options };
};

export default intensivePlus;
