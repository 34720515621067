import React from "react";
import styled from "styled-components";
import FA from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/fontawesome-free-solid";
import withToggle from "../withToggle";
import DescriptionList from "../DescriptionList";

const Descriptions = styled.div`
  text-align: center;
  margin-top: 2rem;
  @media (min-width: 640px) {
    text-align: left;
  }
`;

const StyledLink = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.primary};
`;

const ComponentDescriptions = ({ descriptionsShow, toggleDescriptionsShow, selected }) => (
  <Descriptions>
    <StyledLink onClick={toggleDescriptionsShow}>
      <FA icon={faList} /> {descriptionsShow ? "Hide" : "Show"} component descriptions
    </StyledLink>
    {descriptionsShow ? (
      <DescriptionList onClose={toggleDescriptionsShow} selected={selected} />
    ) : null}
  </Descriptions>
);

export default withToggle({ name: "descriptions", trueName: "show", falseName: "hide" })(
  ComponentDescriptions
);
