import { detectSeries } from "../../manufactureCode";
import seatComponents from "../../seatComponents";

const getTypeData = (type) => {
  return seatComponents.find((v) => v.type === type)?.data || [];
};

const getComponentPrice = ({ type, code }) => {
  const data = getTypeData(type);
  return data.find((v) => v.code === code)?.price;
};

const getPrice = (_selected) => {
  let price = 0;
  console.clear();
  const selected = { ..._selected };

  // use an auto detected series if one hasn't been selected
  if (!selected.series || selected.series === "X") {
    selected.series = detectSeries(selected);

    console.log("auto Detected Series :>> ", selected.series);
  }

  Object.entries(selected).forEach(([type, code]) => {
    const priceSettings = getComponentPrice({ type, code });
    if (!priceSettings) return;
    // simple price, just add it
    if (typeof priceSettings === "number") {
      console.log(JSON.stringify({ type, code, price: priceSettings }, null, 2));
      return (price += priceSettings);
    }

    // price settings is an array, get the correct price according to the selection
    const matchingPriceSetting = priceSettings.find((priceSetting) => {
      const selectedComponentCode = selected?.[priceSetting.component];
      if (priceSetting?.code === "ALL") return true;
      if (priceSetting?.code?.includes(selectedComponentCode)) return true;
      // handle matchAll cases for combined requirements
      if (priceSetting.matchAll) {
        const matches = priceSetting.matchAll.filter((matchSetting) => {
          const matchingComponentCode = selected?.[matchSetting.component];
          if (matchSetting.code === "ALL") return true;
          if (matchSetting.code.includes(matchingComponentCode)) return true;
          return false;
        });
        if (matches.length === priceSetting.matchAll.length) return true;
        return false;
      }
      return false;
    });

    console.log(JSON.stringify({ type, code, price: matchingPriceSetting }, null, 2));

    // if (matchingPriceSetting?.price === undefined)
    //   console.warn(`Possible price issue, missing price for component ${type} = ${code}`);

    return (price += matchingPriceSetting?.price || 0);
  });

  return price;
};

export default getPrice;
