const exe = ({ selected, options }) => {
  let filteredOptions = options;
  // RULE: EXE control - When S base, remove M height (Low only)

  const { control, base, height } = selected || {};

  if (control === "EXE" && height === "M") {
    filteredOptions = filteredOptions.map(({ type, data, ...rest }) => {
      // don't allow S base
      if (type === "base") {
        data = data.filter(({ code }) => code !== "S");
      }
      return {
        type,
        data,
        ...rest,
      };
    });
  }
  if (control === "EXE" && base === "S") {
    filteredOptions = filteredOptions.map(({ type, data, ...rest }) => {
      // only allow L height
      if (type === "height") {
        data = data.filter(({ code }) => code === "L");
      }
      return {
        type,
        data,
        ...rest,
      };
    });
  }
  if (base === "S" && height === "M") {
    filteredOptions = filteredOptions.map(({ type, data, ...rest }) => {
      // remove EXE control
      if (type === "control") {
        data = data.filter(({ code }) => code !== "EXE");
      }
      return {
        type,
        data,
        ...rest,
      };
    });
  }

  return { selected, options: filteredOptions };
};

export default exe;
