import { NotificationManager } from "react-notifications";
import _ from "lodash";

export { NotificationContainer } from "react-notifications";

export const notifications = ({
  error,
  success = false,
  warning = false,
  info = false,
  title = null,
  timeout = 5000,
}) => {
  if (error) {
    let msg = error.reason || error.message || error.error;
    // Attempt to grab more user friendly errors from GraphQL
    const { graphQLErrors } = error;
    if (graphQLErrors) {
      msg = _.reduce(graphQLErrors, (str, { message }) => `${str} ${message} `, "");
    }

    if (!msg) {
      if (typeof error === "string") {
        msg = error;
      } else {
        msg = "An unknown error occured.";
        console.log(error); // eslint-disable-line no-console
      }
    }
    NotificationManager.error(msg, null, timeout);
    console.error(error); // eslint-disable-line no-console
  }
  if (!success && !warning && !info) return;
  let message;
  let messageType;
  if (success) {
    message = success;
    messageType = "success";
  }
  if (warning) {
    message = warning;
    messageType = "warning";
  }
  if (info) {
    message = info;
    messageType = "info";
  }

  NotificationManager[messageType](message, title, timeout);
};

export default notifications;
