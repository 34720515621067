import ALL from "./ALL";

const defaultSeries = [
  "X",
  "MTCL",
  "MTHD",
  "AM",
  "BT",
  "EE",
  "EB",
  "FC",
  "FL",
  "HC",
  "HL",
  "Z",
  "GG",
  "UU",
  "KX",
  "WW",
  "IP",
  "2A",
  "RX",
  "RT",
  "TX",
  "CX",
  "GX",
  "AJ",
  "TXG",
  "Scepter",
];

export default [
  {
    code: "PM",
    name: "PM - MVMT Premium",
    description: `Weight activated Pro control includes 2.5" seat slide which provides functional seat depth from 16.4"
       to 18.9", pneumatic seat height adjustment, forward seat tilt, smooth and seamless forward backrest adjustment
       (a BioFit exclusive), and synchronous seat/back recline at 5:1 ratio. Seat/back recline features an automatic
       weight-adjusted tension mechanism, in addition to manual tension adjustment for fine-tuning and a function to
       lock into one of four reclined angles.`,
    seat: ["1"],
    back: ["MB", "TB"],
    base: ["A", "W", "R"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    footring: ["AFP", "XF"],
    arm: ["3D", "XA"],
    performance: [
      "K",
      "ISO6",
      "ISO5",
      "ISO3",
      "ISO6K",
      "ISO5K",
      "ISO3K",
      "C",
      "06",
      "WD",
      "WDK",
      "ISO8",
      "ISO8K",
      "ISO7",
      "ISO7K",
      "ISO8C",
      "ISO7C",
    ],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["MVMT"],
    price: 604,
  },
  {
    code: "T",
    mvmtCode: "E1",
    name: "T / E1 - Task",
    description: `Control allows function of the seat height activation and backrest adjustments by means of easily
      engaged levers which can be activated from the seated position. Seat tilt is fixed at 3-1/2° rearward while the
      backrest adjusts 8° forward and 13° rearward.`,
    seat: ["1F", "1B", "A", "B", "E", "F", "H", "U", "G", "W", "R", "VF:R"],
    back: ["MB", "TB", "E", "M", "T", "C", "L", "U", "G", "W", "B"],
    base: ["TUH", "A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "HG", "RK"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
    price: [
      {
        component: "style",
        code: ["MVMT"],
        price: 328,
      },
      {
        component: "style",
        code: ["foundation"],
        price: 100,
      },
    ],
  },
  {
    code: "FFAC",
    mvmtCode: "E2",
    name: "FFAC - E2",
    description: `Control allows function of the seat height activation, backrest adjustment, and seat tilt adjustment
      by means of easily engaged levers which can be activated from the seated position. Seat tilts 6° rearward; 4°
      forward; backrest tilts 8° forward; 13° rearward. Tilt adjustments can be locked in place or be left to free
      float.`,
    seat: ["1F", "1B", "A", "B", "E", "F", "H", "U", "G"],
    back: ["MB", "TB", "E", "M", "T", "C", "L", "U", "G", "B"],
    base: ["TUH", "A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "HG", "RK"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    performance: [
      "K",
      "ISO6",
      "ISO6K",
      "C",
      "06",
      "ISO8",
      "ISO8K",
      "ISO7",
      "ISO7K",
      "WD",
      "WDK",
      "ISO5",
      "ISO5K",
      "ISO3",
      "ISO3K",
      "ISO8C",
      "ISO7C",
    ],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
    price: [
      {
        component: "style",
        code: ["MVMT"],
        price: 358,
      },
      {
        component: "performance",
        code: ["ISO5", "ISO5K"],
        price: 243,
      },
      {
        component: "style",
        code: ["foundation"],
        price: 130,
      },
    ],
  },
  {
    code: "E3",
    name: "SYN - E3",
    description: `Synchronous tilting control includes a full 2" of seat slide providing functional seat depth from 16.5"
      to 18.5". The backrest reclines 20° while the seat reclines 12°.`,
    seat: ["1"],
    back: ["MB", "TB"],
    base: ["A", "W", "R"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    footring: ["AFP", "XF"],
    arm: ["3D", "XA"],
    performance: [
      "K",
      "ISO6",
      "ISO6K",
      "C",
      "06",
      "ISO8",
      "ISO8K",
      "ISO7",
      "ISO7K",
      "WD",
      "WDK",
      "ISO8C",
      "ISO7C",
    ],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
    price: [
      {
        component: "style",
        code: ["MVMT"],
        price: 438,
      },
    ],
  },
  {
    code: "EXE",
    name: "EXE",
    description: `Executive Tilt Control: Pneumatic seat- and backrest-height adjustment; backrest tilt; seat and backrest swivel tilt in unison; tilt tension control knob and single lever seat tilt lock into any position. Only available on models with maximum seat height of 26" or less.`,
    seat: ["A", "B", "E", "F", "H"],
    back: ["E", "M", "T", "C", "L", "S", "B"],
    base: ["A", "S", "R", "W"],
    height: ["L", "M"],
    casters: ["RC", "HG", "RK"],
    footring: ALL,
    arm: ["ARS", "PSAS", "XA"],
    performance: [
      "K",
      "ISO6",
      "ISO6K",
      "C",
      "06",
      "ISO8",
      "ISO8K",
      "ISO7",
      "ISO7K",
      "ISO8C",
      "ISO7C",
    ],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
    price: 306,
  },
  {
    code: "T:SLD",
    name: "T:SLD - Task with Seat Depth Adj.",
    description: `Task Control with Seat Slider: Control allows function of the seat height activation and backrest adjustments by means of easily engaged levers which can be activated from the seated position. Seat tilt is fixed at 3.5° rearward while the backrest adjusts 8° forward and 13° rearward. Seat slider bracket allows seat depth adjustment range of up to 2.5" wit locking positions every 0.5". This option is available only on models with R or W bases with low or medium seat height ranges (26" or less); not recommended on clean room models.`,
    seat: ["A", "F"],
    back: ["E", "M", "C", "L", "B"],
    base: ["A", "R", "W"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    footring: ALL,
    arm: ["ARS", "PSAS", "XA"],
    performance: [
      "K",
      "C",
      "06",
      "ISO6",
      "ISO6K",
      "ISO7",
      "ISO7K",
      "ISO8",
      "ISO8K",
      "ISO8C",
      "ISO7C",
    ],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
    price: 231,
  },
  {
    code: "FFAC:SLD",
    name: "FFAC:SLD - FFAC with Seat Depth Adj.",
    description: `Free-Float Articulating Control with Seat Slider: Control allows function of the seat height activation, backrest adjustment, and seat tilt adjustment by means of easily engaged levers which can be activated from the seated position. Seat tilts 6° rearward; 4° forward; backrest tilts 8° forward; 13° rearward. Tilt adjustments can be locked in place or be left to free float. Seat slider bracket allows seat depth adjustment range of up to 2.5" with locking positions every 0.5". This option is available only on models with R or W bases with low or medium seat height ranges (26" or less); not recommended on clean room models.`,
    seat: ["A", "F"],
    back: ["E", "M", "C", "L", "B"],
    base: ["A", "R", "W"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    footring: ALL,
    arm: ["ARS", "PSAS", "XA"],
    performance: [
      "K",
      "C",
      "06",
      "ISO6",
      "ISO6K",
      "ISO7",
      "ISO7K",
      "ISO8",
      "ISO8K",
      "ISO8C",
      "ISO7C",
    ],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
    price: 261,
  },
  {
    code: "EXE:SLD",
    name: "EXE:SLD - EXE with Seat Depth Adj.",
    description: `Executive Tilt Control with Seat Slider: Pneumatic seat- and backrest-height adjustment; backrest tilt; seat and backrest swivel tilt in unison; tilt tension control knob and single lever seat tilt lock into any position. Seat slider bracket allows seat depth adjustment range of up to 2.5" with locking positions every 0.5". This option is available only on models with R or W bases with low or medium seat height ranges (26" or less); not recommended on clean room models.`,
    seat: ["A", "F", "VF:F"],
    back: ["E", "M", "C", "L", "B"],
    base: ["R", "W"],
    height: ["L", "M"],
    casters: ["RC", "RK", "HG"],
    footring: ALL,
    arm: ["ARS", "PSAS", "XA"],
    performance: [
      "K",
      "C",
      "06",
      "ISO6",
      "ISO6K",
      "ISO7",
      "ISO7K",
      "ISO8",
      "ISO8K",
      "ISO8C",
      "ISO7C",
    ],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
    price: 437,
  },
  {
    code: "S",
    name: "S - Stool Control",
    description: `Task control with Soft Touch pneumatic seat height adjustment.`,
    seat: ["T", "C", "R", "VF:R"],
    back: ["X"],
    base: ["A", "S", "R", "W", "G"],
    height: ALL,
    casters: ["RC", "HG", "RK", "GL"],
    footring: ALL,
    arm: ["XA"],
    performance: ["K", "ISO6", "ISO4", "ISO4K", "ISO5", "ISO5K", "ISO6", "ISO6K", "C", "06"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
    price: 86,
  },
  {
    code: "Z",
    name: "Z - Zephyr Control",
    description: `Executive-style control features pneumatic seat-heat adjustment, backrest-tilt adjustment, seat-tilt adjustment and spring-tension control knob.`,
    seat: ["A", "F"],
    back: ["P"],
    base: ["R", "W"],
    height: ALL,
    casters: ["RC", "HG"],
    footring: ALL,
    arm: ["ARS", "PSAS", "XA"],
    performance: ["C", "06"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
    price: 330,
  },
  {
    code: "P",
    name: "P - Polyshell Control",
    description: `Control features Soft Touch pneumatic seat-height adjustment.`,
    seat: ["K"],
    back: ["X"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "HG"],
    footring: ALL,
    arm: ["XA"],
    performance: ["ISO5", "C", "06", "ISO7", "ISO8", "ISO8C", "ISO7C"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
    price: 120,
  },
  {
    code: "FA",
    name: "FA - Foot Activated",
    description: `Ring-shaped foot-activated control enables hands-free pneumatic seat height adjustment.`,
    seat: ["R", "VF:R"],
    back: ["X"],
    base: ["C"],
    height: ["L", "M"],
    casters: ["CRC"],
    footring: ["XF"],
    arm: ["XA"],
    performance: ["C"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
  },
  {
    code: "X",
    name: "No Control",
    description: "",
    seat: ["TX"],
    back: ["X"],
    base: ["A18", "A24", "A30"],
    height: ALL,
    casters: ["HG", "GL"],
    footring: ["XF"],
    arm: ["XA"],
    performance: ["ISO4", "C", "06"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
  },
  {
    code: "L",
    name: "L - Sit/Stand Control",
    description: `Features Soft Touch pneumatic seat-height adjustment and seat-tilt adjustment from horizontal to 6 degrees forward.`,
    seat: ["L"],
    back: ["X"],
    base: ["S"],
    height: ["M", "H"],
    casters: ["HG"],
    control: ["L"],
    footring: ["XF"],
    arm: ["XA"],
    performance: ["06", "C"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
  },
  {
    code: "IP",
    name: "IP - Intensive Plus",
    description: `Executive Seat Control with Seat Slider: Pneumatic seat height adjustment and backrest height adjustment; seat and backrest swivel/tilt in unison; tilt-tension control knob and single lever seat tilt lock in any position. Seat slider allows seat depth adjustment range of up to 2.5" with locking positions every 0.5".`,
    seat: ["F"],
    back: ["S"],
    base: ["P"],
    height: ["M"],
    casters: ["HG", "IPR"],
    footring: ["XF"],
    arm: ["ISAS", "XA"],
    performance: ["06"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
  },
];
