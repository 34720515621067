const footringDescription = ({ selected }) => {
  const { base } = selected || {};
  if (base === "S")
    return "21” diameter non-adjustable 1” x 18-gauge tubular steel ring welded to top of legs, 8” above floor surface.";
  if (base === "TUH")
    return "21” diameter non-adjustable 1” x 18-gauge tubular steel ring welded to top of legs, 12” above floor surface.";
  return "";
};

export default footringDescription;
