import ALL from "./ALL";

export default [
  {
    code: "X",
    name: "No Under Seat Upholstery",
    description: "",
    seat: ALL,
    back: ALL,
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ALL,
    memoryFoam: ALL,
    series: ALL,
  },
  {
    code: "VU",
    name: "Under Seat Upholstery",
    description: "Under side of seat upholstered to match top of seat.",
    seat: ["1F", "1B", "A", "B", "E", "F", "H"],
    back: ALL,
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ALL,
    memoryFoam: ALL,
    series: ALL,
    price: 40,
  },
];
