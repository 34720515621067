import React from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import { Flex, Box } from "grid-styled";
import { PageContainer } from "../Grid";
import { H1, H2 } from "../TextStyles";
import Button from "../Button";
import tableImage from "./table.jpg";

const Warranty = ({ match }) => {
  const { manufactureDate } = match.params;
  if (!manufactureDate) return null;

  const d = moment(manufactureDate, "MM-DD-YYYY");
  if (!d.isBefore()) return null;

  const expires = moment(d).add(13, "years");

  const warrantyLeft = () => {
    if (expires.isBefore()) return false;

    const now = moment();
    const years = expires.diff(now, "year");
    now.add(years, "years");

    const months = expires.diff(now, "months");
    now.add(months, "months");

    const days = expires.diff(now, "days");

    const plural = (num) => {
      if (num === 1) return "";
      return "s";
    };
    let left = "";
    if (years > 0) left += `${years} year${plural(years)}, `;
    if (months > 0) left += `${months} month${plural(months)}, `;
    left += `${days} day${plural(days)}`;
    return left;
  };

  const warrantyString = warrantyLeft();

  return (
    <div>
      <H2>Warranty Information</H2>
      <p> Your table was manufactured on {d.format("MMM Do, YYYY")}</p>
      {warrantyString
        ? `You have ${warrantyString} left on your 13 year warranty.`
        : "Your 13 year warranty has expired."}
    </div>
  );
};

const TableDetails = ({ match }) => {
  const { manufactureCode } = match.params;

  return (
    <PageContainer>
      <Helmet>
        <title>My Table Profile</title>
      </Helmet>
      <H1>Your BioFit Table Profile</H1>
      <Flex flexWrap="wrap">
        <Box width={[1, 0.5]}>
          <p>
            This resource allows you to view your table&apos;s manufacture code and verify the
            status of your 13-year warranty.
          </p>
          <p>
            Your BioFit table has manufacture code <strong>{manufactureCode}</strong>.
          </p>

          <Warranty match={match} />
          <p>
            Please <a href="https://www.biofit.com/contact">contact BioFit</a> with any questions!
          </p>
          <a href="https://www.biofit.com">
            <Box ml="-0.5rem">
              <Button>Visit BioFit.com</Button>
            </Box>
          </a>
        </Box>
        <Box width={[1, 0.5]}>
          <img src={tableImage} alt="Person pushing folded cafeteria table" />
        </Box>
      </Flex>
    </PageContainer>
  );
};

export default TableDetails;
