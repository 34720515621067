import _ from "lodash";
import calculateAvailableOptions from "./calculateAvailableOptions";

const applySpecificDefaults = (selected) => {
  const { height, base } = selected || {};

  // Default to AFP for A,R,W base when M or H height
  if (["M", "H"].indexOf(height) !== -1 && ["A", "R", "W"].indexOf(base) !== -1) {
    selected.footring = "AFP";
  }
  return selected;
};

const removeInvalidSelections = (initialSelected, forcedType) => {
  const selected = applySpecificDefaults(initialSelected);
  selected[forcedType] = initialSelected[forcedType];
  const { options } = calculateAvailableOptions() || [];

  const { data: set } = options.filter(({ type }) => type === forcedType)[0] || {};

  // Get the valid options for each component when the forced type is selected
  const validOptions = (set || []).filter(({ code }) => code === selected[forcedType])[0];

  const newSelected = _.mapValues(selected, (code, type) => {
    if (type === forcedType) return code;

    if (!validOptions) return undefined;
    if (validOptions[type].indexOf(code) !== -1) return code;
    return undefined;
  });

  return newSelected;
};

export default removeInvalidSelections;
