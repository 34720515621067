import React from "react";
import { Formik, Form } from "formik";
import { Flex, Box } from "grid-styled";
import { withProps } from "recompose";
import { withRouter } from "react-router-dom";
import FA from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/fontawesome-free-solid";
import { Helmet } from "react-helmet";
import { PageContainer } from "../Grid";
import { H1 } from "../TextStyles";
import { LargeButton } from "../Button";
import { Text } from "../../ui/Forms";
import schema from "./schema";
import chairUrlString from "../chairUrlString";

const TextField = withProps({ schema })(Text);

const FindChairForm = (props) => (
  <Form>
    <Flex justifyContent="center">
      <Box width={[1, 3 / 4, 1 / 2, 1 / 3]}>
        <TextField name="manufactureCode" />
        <TextField placeholder="MM-DD-YYYY" name="manufactureDate" />
        <TextField name="invoiceNumber" />

        <LargeButton active type="submit" disabled={!props.isValid}>
          <FA icon={faSearch} /> Find My Chair
        </LargeButton>
      </Box>
    </Flex>
  </Form>
);

const FindChair = ({ history }) => {
  const routeToChairDetails = (values) => {
    history.push(`/chair${chairUrlString(values)}`);
  };

  return (
    <PageContainer>
      <Helmet>
        <title>Find My Chair</title>
      </Helmet>
      <H1>Find My Chair</H1>
      <p>
        Enter your information below to access assembly and operational videos that will help you
        get the most out of your BioFit chair. You may also check the status of your 13-year
        warranty. Either way, we’ve made it quick and easy to gain access to the information you
        need!
      </p>

      <div>
        <Formik
          initialValues={{
            manufactureCode: "",
            manufactureDate: "",
            invoiceNumber: "",
          }}
          validationSchema={schema}
          onSubmit={routeToChairDetails}
          component={FindChairForm}
        />
      </div>
    </PageContainer>
  );
};

export default withRouter(FindChair);
