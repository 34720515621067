import _ from "lodash";
import seatComponents from "./seatComponents";
import selectionsAreValid from "./selectionsAreValid";
import calculateAvailableOptions from "./calculateAvailableOptions";

// provides selection object using MVMT codes when mvmtCode is present in component data
export const fromCodeToMvmtCode = (selected) => {
  return _.mapValues(selected, (code, type) => {
    const set = seatComponents.filter(({ type: typeCompare }) => type === typeCompare)[0] || {};
    const { data } = set;

    const { mvmtCode } =
      (data || []).filter(({ code: codeCompare }) => code === codeCompare)[0] || {};

    if (mvmtCode) return mvmtCode;
    return code;
  });
};

// converts selection provided with MVMT codes to normal codes
export const fromMvmtCodeToCode = (selected) => {
  const convertPerformanceCodes = {
    T: "C",
    8: "ISO8",
    "8K": "ISO8K",
    7: "ISO7",
    "7K": "ISO7K",
    6: "ISO6",
    "6K": "ISO6K",
    5: "ISO5",
    "5K": "ISO5K",
    3: "ISO3",
    "3K": "ISO3K",
  };

  const { performance } = selected || {};
  if (convertPerformanceCodes[performance]) {
    selected.performance = convertPerformanceCodes[performance];
  }

  return _.mapValues(selected, (code, type) => {
    const set = seatComponents.filter(({ type: typeCompare }) => type === typeCompare)[0] || {};
    const { data } = set;

    const { code: finalCode } =
      (data || []).filter(
        ({ code: codeCompare, mvmtCode }) => mvmtCode === code || codeCompare === code
      )[0] || {};

    return finalCode;
  });
};

// Detect MVMT series line (Pro, Tech, HD)
// Pro series if seat is 1, unless controls are E3 (SYN)
// HD series if base is TUL (S) or TUH
export const getMvmtSeries = (mvmtSelected) => {
  const { seat, control, base } = mvmtSelected || {};
  let series = null;
  if (seat === "1F" || seat === "1B") series = "MTCL";
  if (seat === "1" && control !== "E3") series = "MP";
  if (seat === "1" && control === "E3") series = "MTCL";
  if (base === "TUL" || base === "TUH") series = "MTHD";
  return series;
};

const defaultOptions = {
  seat: "�",
  back: "�",
  base: "�",
  height: "�",
  casters: "�",
  control: "�",
  footring: "�",
  arm: "�",
  performance: "�",
  vinylUnderwrap: "X",
  memoryFoam: "X",
  series: "X",
  upholstery: "�",
};

export const getOptionString = (selected) => {
  // return "X";
  const { vinylUnderwrap = "X", memoryFoam = "X" } = selected || {};

  const options = [vinylUnderwrap, memoryFoam].filter((o) => o !== "X").join("-");
  if (!options) return "";
  return `-${options}`;
};

export const buildManufactureCode = (selected) => {
  const selectedComponents = { ...selected };

  _.defaults(selectedComponents, defaultOptions);

  let seat, back, base, height, casters, control, footring, arm, performance, upholstery;

  const mvmtSelected = fromCodeToMvmtCode(selectedComponents);

  // Detect if MVMT Series
  const isMVMT = _.startsWith(mvmtSelected.seat, "1");

  if (!isMVMT) {
    ({ seat, back, base, height, casters, control, footring, arm, performance, upholstery } =
      selectedComponents || {});

    // Convert the AJ stools manufacture code
    if (seat === "TX") {
      if (base === "A18") return `AJ-18-${performance}`;
      if (base === "A24") return `AJ-24-${performance}`;
      if (base === "A30") return `AJ-30-${performance}`;
    }

    let chrome = "";
    if (performance === "ISO8C") {
      chrome = "C-";
      performance = "ISO8";
    }
    if (performance === "ISO7C") {
      chrome = "C-";
      performance = "ISO7";
    }

    const options = getOptionString(selectedComponents);

    return `${seat}${back}${base}-${height}-${casters}-${control}-${footring}-${arm}-${performance}${options}>${chrome}${upholstery}`;
  }

  ({ seat, back, base, height, casters, control, footring, arm, performance, upholstery } =
    mvmtSelected || {});

  const series = getMvmtSeries(mvmtSelected);

  const options = getOptionString(mvmtSelected);

  let chrome = "";
  if (performance === "ISO8C") {
    chrome = "C-";
    performance = "ISO8";
  }
  if (performance === "ISO7C") {
    chrome = "C-";
    performance = "ISO7";
  }

  return `${series}-${seat}-${back}-${base}-${height}-${casters}-${control}-${footring}-${arm}-${performance}${options}>${chrome}${upholstery}`;
};

// Goes through each letter in a code combination and matches it to available codes within a type
const findPartMatch = ({ type, codeCombination }) => {
  // split the combination by letter
  const split = codeCombination.split("");

  const set = seatComponents.filter(({ type: typeCompare }) => type === typeCompare)[0] || {};
  const { data } = set;
  let codes = data.map(({ code }) => code);

  let findLetters = "";
  let exactMatch;

  _.find(split, (letter) => {
    findLetters = `${findLetters}${letter}`;

    // console.log(`Finding ${findLetters} within ${codes}`);

    codes = codes.filter((code) => code.startsWith(findLetters));

    if (codes.length === 1) {
      exactMatch = null; // clear exact for next component set
      return true;
    }
    // Check for an exact match
    if (!exactMatch) exactMatch = codes.filter((code) => code === findLetters);
  });

  if (exactMatch && exactMatch.length > 0) return exactMatch[0];

  if (codes.length === 0) {
    // console.error(`ERROR: No match found for ${codeCombination} within ${type}`);
    return "�";
  }

  if (codes.length > 1) {
    // console.error(`ERROR: Multiple matches found for ${codeCombination} within ${type}`);
    return "�";
  }
  return codes[0];
};

const optionObjectFromArray = (optionArray = []) => {
  const options = {
    vinylUnderwrap: "X",
    memoryFoam: "X",
  };

  const optionCodes = {
    VU: "vinylUnderwrap",
    FM: "memoryFoam",
  };

  optionArray.forEach((option) => {
    if (!optionCodes[option]) return;
    options[optionCodes[option]] = option;
  });
  return options;
};

export const buildSelectedFromCode = (code = "", { market, style, upholstered, series } = {}) => {
  // Remove extra bits from the manufacture code (after >)
  const [removedExtra, extra] = code.split(">");
  const parts = removedExtra.split("-");

  let part0,
    __ignoredSeries, // eslint-disable-line no-unused-vars
    seat,
    back,
    base,
    height,
    casters,
    control,
    footring,
    arm,
    performance,
    optionsArray,
    upholstery;

  // Detect if this is MVMT Series
  const mvmtSeries = ["MP", "MTCL", "MTHD"];
  const [potentialSeries] = parts || [];
  const isMVMT = mvmtSeries.indexOf(potentialSeries) !== -1;

  if (isMVMT) {
    [
      // eslint-disable-next-line no-unused-vars
      __ignoredSeries,
      seat,
      back,
      base,
      height,
      casters,
      control,
      footring,
      arm,
      performance,
      ...optionsArray
    ] = parts;
  } else {
    // Part 0 is special, seatbackbase
    [part0, height, casters, control, footring, arm, performance, ...optionsArray] = parts;

    // convert AJ stools
    if (part0 === "AJ") {
      let aj1, aj2; // eslint-disable-line no-unused-vars
      // eslint-disable-next-line no-unused-vars
      [aj1, aj2, performance] = parts;

      seat = "TX";
      back = "X";
      if (aj2 === "18") {
        base = "A18";
        height = "L";
      }
      if (aj2 === "24") {
        base = "A24";
        height = "M";
      }
      if (aj2 === "30") {
        base = "A30";
        height = "H";
      }
      casters = "GL";
      control = "X";
      footring = "XF";
      arm = "XA";
    } else {
      if (part0.length === 3) {
        // split the first part by letter
        const part0split = part0.split("");
        [seat, back, base] = part0split;
      } else {
        // Figure out seat
        seat = findPartMatch({ type: "seat", codeCombination: part0 });

        // Remove seat string from code combination and find back
        const [ignore, ...backBase] = part0.split(seat); // eslint-disable-line no-unused-vars
        const backBaseCode = backBase.join();
        back = findPartMatch({ type: "back", codeCombination: backBaseCode });

        // Remove back string from back and base code combination and find base
        const [ignore2, ...baseArray] = backBaseCode.split(back); // eslint-disable-line no-unused-vars
        const baseCode = baseArray.join();
        base = findPartMatch({ type: "base", codeCombination: baseCode });
      }
    }
  }

  const { vinylUnderwrap, memoryFoam } = optionObjectFromArray(optionsArray);

  //get upholstery option - stored after >, potentially with other items separated by -
  const extraParts = (extra || "").split("-");
  const upholsterySet = seatComponents.filter(({ type }) => type === "upholstery")[0] || {};
  extraParts.forEach((v) => {
    const selectedUpholstery = (upholsterySet.data || []).find(({ code }) => code === v);
    if (selectedUpholstery) upholstery = selectedUpholstery.code;
    // handle ISO7C, ISO8C manufacture code conversion
    if (v === "C" && ["ISO7", "ISO8"].includes(performance)) performance = `${performance}C`;
  });

  let selected = {
    market,
    style,
    upholstered,
    seat,
    performance,
    back,
    base,
    height,
    casters,
    control,
    footring,
    arm,
    vinylUnderwrap,
    memoryFoam,
    upholstery,
    series,
  };
  if (isMVMT) {
    selected = fromMvmtCodeToCode(selected);
  }
  _.defaults(selected, defaultOptions);

  return selected;
};

export const manufactureCodeIsValid = ({ code, selected }) => {
  const s = selected || buildSelectedFromCode(code);

  return selectionsAreValid(s);
};

export default buildManufactureCode;

export const selectFirstValidFromSelection = (initialSelected, config = {}) => {
  let selected = { ...initialSelected };

  const cfg = {
    forceType: null,
    ...config,
  };

  let options;
  _.defaults(selected, defaultOptions);

  // handle SYN / MTCL
  if (selected.series === "MTCL" && selected.seat === "�") selected.seat = "1F";
  if (selected.series === "MTCL" && selected.seat === "1") selected.control = "E3";

  // handle AJ stools
  if (selected.series === "AJ") selected.seat = "TX";

  ({ selected, options } = calculateAvailableOptions(selected, { forceType: cfg.forceType }));

  // If this eliminated a component's available options, set the option from the first available from a forced type,
  // or from all components if a forced type is not set
  const forcedSelected = {};
  if (cfg.forceType) forcedSelected[cfg.forceType] = selected[cfg.forceType];
  const { options: forcedAvailableOptions } = calculateAvailableOptions(forcedSelected, {
    forceType: cfg.forceType,
  });

  // console.log("options before", options);

  options = options.map(({ data, type, ...rest }) => {
    let optionData = data;
    if (optionData.length === 0) {
      optionData = forcedAvailableOptions.filter(
        ({ type: forcedAvailableType }) => type === forcedAvailableType
      )[0].data;
    }

    return { data: optionData, type, ...rest };
  });

  // console.log("options after", options);
  //
  // console.log("selected before", { ...selected });

  // place selected in order of how to automatically set options the best
  // const selectedArray = [
  //   {type: "market", code: selected.market}
  // ]

  // Automatically set first option if not selected or is invalid
  _.forOwn(selected, (code, type) => {
    const { data } = options.filter(({ type: typeCompare }) => typeCompare === type)[0] || {};

    if (!code || data.filter(({ code: codeCompare }) => code === codeCompare).length === 0) {
      ({ selected, options } = calculateAvailableOptions(selected, { forceType: cfg.forceType }));

      options = options.map(({ data, type, ...rest }) => {
        let optionData = data;
        if (optionData.length === 0) {
          optionData = forcedAvailableOptions.filter(
            ({ type: forcedAvailableType }) => type === forcedAvailableType
          )[0].data;
        }

        return { data: optionData, type, ...rest };
      });

      // console.log("options :>> ", { ...options });

      const { data: availableSet } = options.filter(({ type: type2 }) => type === type2)[0] || {};

      const [firstOption = {}] = availableSet || [];

      // console.log(`setting ${firstOption.code} for ${type}`);

      selected[type] = firstOption.code;
    }
  });

  // console.log("selected after", { ...selected });

  return selected;
};

export const detectSeries = (selected) => {
  const { options } = calculateAvailableOptions(selected);
  const seriesOptions = (options.find(({ type }) => type === "series")?.data || []).filter(
    ({ code }) => code !== "X"
  );
  const series = seriesOptions?.[0]?.code;

  if (!series) throw new Error("Cannot determine series");

  return series;
};
