import ALL from "./ALL";
import heightDescription from "./heightDescription";

export default [
  {
    code: "L",
    name: "L - Low",
    description: `For low working heights such as desks or benches 32" or shorter. Provides a range of 17" - 21".`,
    dynamicDescription: (selected) => heightDescription({ selected, height: "L" }),
    seat: [
      "1",
      "1F",
      "1B",
      "A",
      "B",
      "E",
      "F",
      "H",
      "VF:F",
      "VF:H",
      "U",
      "G",
      "W",
      "K",
      "T",
      "C",
      "R",
      "VF:R",
      "TX",
    ],
    back: ALL,
    base: ["A", "S", "R", "W", "G", "C", "A18"],
    casters: ["RC", "RK", "HG", "GL", "CRC"],
    control: [
      "PM",
      "E3",
      "T",
      "FFAC",
      "EXE",
      "T:SLD",
      "FFAC:SLD",
      "EXE:SLD",
      "P",
      "Z",
      "S",
      "FA",
      "X",
    ],
    footring: ["XF"],
    arm: ["3D", "ARS", "PSAS", "XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    price: [
      {
        component: "style",
        code: ["MVMT"],
        price: 42,
      },
      {
        component: "seat",
        code: ["L"],
        price: 0,
      },
      {
        component: "base",
        code: ["R", "A", "W", "S", "C"],
        price: 33,
      },
    ],
  },
  {
    code: "M",
    name: "M - Medium",
    description: `For medium height work surfaces such a workbenches 32" to 34" high.  Provides a range of 20" - 27".
    For Sit/Stand Seat: Medium working heights such a workbenches 34" to 41" high. Provides a range of 22" - 29".`,
    dynamicDescription: (selected) => heightDescription({ selected, height: "M" }),
    seat: ALL,
    back: ALL,
    base: ["A", "S", "R", "W", "G", "C", "A24", "P"],
    casters: ALL,
    control: ALL,
    footring: ALL,
    arm: ALL,
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    price: [
      {
        component: "style",
        code: ["MVMT"],
        price: 42,
      },
      {
        component: "seat",
        code: ["L"],
        price: 0,
      },

      {
        component: "base",
        code: ["R", "A", "W", "S"],
        price: 44,
      },
      {
        component: "base",
        code: ["C"],
        price: 98,
      },
    ],
  },
  {
    code: "H",
    name: "H - High",
    description: `For high working heights such as standing height benches measuring 36" up to 40+".
      Provides a range of 24" - 33.5".
      For Sit/Stand Seat: High working heights such as standing height benches measuring 36" up to 40+". Provides a range of 24" - 34".`,
    dynamicDescription: (selected) => heightDescription({ selected, height: "H" }),
    seat: ALL,
    back: ["MB", "TB", "E", "M", "T", "C", "L", "U", "G", "W", "X", "B"],
    base: ["TUH", "A", "S", "R", "W", "A30"],
    casters: ["RC", "RK", "HG", "GL"],
    control: ["PM", "E3", "T", "FFAC", "T:SLD", "FFAC:SLD", "P", "Z", "S", "X", "L"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: [
      "X",
      "MVMT",
      "MTCL",
      "MTHD",
      "AM",
      "BT",
      "EE",
      "EB",
      "FC",
      "FL",
      "HC",
      "HL",
      "VF:FC",
      "VF:FL",
      "VF:HC",
      "VF:HL",
      "GG",
      "UU",
      "KX",
      "WW",
      "IP",
      "2A",
      "RX",
      "RT",
      "TX",
      "CX",
      "GX",
      "AJ",
      "TXG",
      "Scepter",
    ],
    price: [
      {
        component: "style",
        code: ["MVMT"],
        price: 53,
      },
      {
        component: "seat",
        code: ["L"],
        price: 0,
      },
      {
        component: "base",
        code: ["R", "A", "W", "S"],
        price: 57,
      },
    ],
  },
];
