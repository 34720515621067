const iso3Control = ({ selected, options }) => {
  let filteredOptions = options;
  // RULE: FFAC control is only available for ISO 3 when seat is 1F

  const { control, seat, performance } = selected || {};

  if (control === "FFAC" && seat !== "1F") {
    filteredOptions = filteredOptions.map(({ type, data, ...rest }) => {
      // don't allow ISO3 / 3K
      if (type === "performance") {
        data = data.filter(({ code }) => ["ISO3", "ISO3K"].indexOf(code) === -1);
      }
      return {
        type,
        data,
        ...rest,
      };
    });
  }

  if (["ISO3", "ISO3K"].indexOf(performance) !== -1 && seat !== "1F") {
    filteredOptions = filteredOptions.map(({ type, data, ...rest }) => {
      // remove FFAC
      if (type === "control") {
        data = data.filter(({ code }) => code !== "FFAC");
      }
      return {
        type,
        data,
        ...rest,
      };
    });
  }

  if (["ISO3", "ISO3K"].indexOf(performance) !== -1 && control === "FFAC") {
    filteredOptions = filteredOptions.map(({ type, data, ...rest }) => {
      // remove all seats but 1F
      if (type === "seat") {
        data = data.filter(({ code }) => code === "1F");
      }
      return {
        type,
        data,
        ...rest,
      };
    });
  }

  return { selected, options: filteredOptions };
};

export default iso3Control;
