import React from "react";
import FA from "@fortawesome/react-fontawesome";
import { faPaste } from "@fortawesome/fontawesome-free-solid";
import styled from "styled-components";
import { Box } from "grid-styled";
import seatComponents from "../../seatComponents";
import { LargeButton } from "../Button";
import getPrice from "../Pricing/getPrice";
import getImageNames from "../../getImageNames";

const { REACT_APP_IMAGES_URL: imageUrl } = process.env;

const encodeObject = (obj) => {
  try {
    return Buffer.from(JSON.stringify(obj))
      .toString("base64")
      .replace(/\+/g, "-")
      .replace(/\//g, "_");
  } catch (error) {
    console.log("error :>> ", error);
    throw new Error("Failed to encode object");
  }
};

const GetQuoteStyle = styled.div`
  position: relative;
  z-index: 1;
  color: ${({ theme }) => theme.primary};
  text-align: center;
  font-size: 0.9rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const GetQuote = ({ manufactureCode, selected }) => {
  // const { pricingEnabled } = usePricing();
  const price = getPrice(selected);

  const { upper, lower } = getImageNames(selected);

  let seriesName = "";
  // Build configuration data for eBeacon
  const config = seatComponents.map(({ name, type, data }) => {
    const selectedData = data.find(({ code }) => code === selected[type]) || {};

    if (type === "series") {
      seriesName = selectedData.name;
    }

    return {
      component: name,
      value: selectedData.name,
    };
  });

  const item = {
    title: `${seriesName}: Custom Build`,
    price: price,
    manufactureCode,
    imageTopUrl: `${imageUrl}${upper}-000.png`,
    imageBottomUrl: `${imageUrl}${lower}-000.png`,
    components: config,
    productUrl: window.location.href,
  };

  // base 64 encode the itemJSON
  const itemJSON = encodeObject(item);

  const quoteLink = `${process.env.REACT_APP_ADD_TO_CART_URL}?item=${itemJSON}`;

  return (
    <GetQuoteStyle>
      <a href={quoteLink}>
        <LargeButton>
          <FA icon={faPaste} /> Get a Quote
        </LargeButton>
      </a>

      <Box mt="1rem">{manufactureCode}</Box>
    </GetQuoteStyle>
  );
};

// const GetQuote = ({ manufactureCode, selected }) => {
//   const { pricingEnabled } = usePricing();
//   const price = pricingEnabled ? getPrice(selected) : undefined;

//   // Build configuration data for eBeacon
//   const config = seatComponents.map(({ name, type, data }) => {
//     const selectedData = data.find(({ code }) => code === selected[type]) || {};

//     return {
//       component: type,
//       componentLabel: name,
//       selectedValue: selectedData.code,
//       selectedLabel: selectedData.name,
//       selectedDescription: selectedData.description,
//     };
//   });
//   return (
//     <GetQuoteStyle>
//       <form action={process.env.REACT_APP_EBEACON_URL} method="POST">
//         <input type="hidden" name="sku" value={manufactureCode} />
//         <input type="hidden" name="permalink" value={window.location} />
//         <input type="hidden" name="configuration" value={JSON.stringify(config)} />
//         <input type="hidden" name="base_price" value={price} />
//         <LargeButton type="submit">
//           <FA icon={faPaste} /> Get a Quote
//         </LargeButton>
//       </form>
//       <Box mt="1rem">{manufactureCode}</Box>
//     </GetQuoteStyle>
//   );
// };

export default GetQuote;
