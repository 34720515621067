import styled, { css } from "styled-components";
import { Box } from "grid-styled";

const common = css`
  font-weight: 200;
  color: ${({ theme }) => theme.primary};
`;

export const H1 = styled.h1`
  ${common};
`;

export const H2 = styled.h2`
  ${common};
`;

export const H3 = styled.h3`
  ${common};
`;

export const H4 = styled.h4`
  ${common};
`;

export const H5 = styled.h5`
  ${common};
`;

export const H6 = styled.h6`
  ${common};
`;

export const DescriptionText = styled(Box)`
  color: #6d6e70;
  font-size: 14px;
  line-height: 24px;
`;
