import React from "react";
import { Field } from "formik";
import styled from "styled-components";
import withFieldProps from "./withFieldProps";
import { FieldWrap, Label, FieldError } from "./Styles";

export const StyledTextInput = styled.input`
  padding: 0.5rem 0.75rem;
  border: 1px solid #ccc;
  width: 100%;
  font-size: 1rem;
  margin: 0.5rem 0;
  &:disabled {
    color: #aaa;
  }
`;

const TextField = ({ name, label, error, required, field, type = "text", ...props }) => {
  return (
    <FieldWrap>
      <Label required={required}>{label}</Label>
      <StyledTextInput id={name} type={type} {...field} {...props} />
      {error ? <FieldError>{error}</FieldError> : null}
    </FieldWrap>
  );
};

const EnhancedText = withFieldProps(TextField);
const Text = (props) => <Field {...props} component={EnhancedText} />;
export default Text;
