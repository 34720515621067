const seatUArmControl = ({ selected: initialSelected, options: initialOptions, forceType }) => {
  let selected = initialSelected;
  let options = initialOptions;
  // RULE: Seat / Back U + ARS arms - only allow T control - not FFAC

  const { control, seat, arm } = selected || {};

  if (seat === "U" && control === "FFAC" && forceType !== "arm") {
    selected.arm = "XA";
    return { selected, options };
  }

  if (arm === "ARS" && seat === "U" && forceType !== "control") {
    selected.control = "T";

    options = options.map(({ type, data, ...rest }) => {
      // don't allow control FFAC
      if (type === "control") {
        data = data.filter(({ code }) => code !== "FFAC");
      }
      return {
        type,
        data,
        ...rest,
      };
    });

    return { selected, options };
  }

  return { selected, options };
};

export default seatUArmControl;
