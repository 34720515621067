import _ from "lodash";
import { buildSelectedFromCode, detectSeries } from "./manufactureCode";

const operational = [
  {
    title: "Armrest Height Adjustment",
    youtubeId: "ku5icLwaaqI",
    include: {
      series: ["MVMT"],
    },
    exclude: {
      arm: ["XA"],
    },
  },
  {
    title: "Armrest Width/Height Adjustment",
    youtubeId: "V5tnQT3v8g4",
    include: {
      series: ["MVMT"],
    },
    exclude: {
      arm: ["XA"],
    },
  },
  {
    title: "Backrest Forward / Backward Adjustment",
    youtubeId: "cSLSR0uED3s",
    include: {
      series: ["MVMT"],
    },
  },
  {
    title: "Backrest Height Adjustment",
    youtubeId: "VJH03hIyYfY",
    include: {
      series: ["MVMT"],
    },
  },
  {
    title: "Footring Adjustment",
    youtubeId: "YqR2zo7MgfQ",
    include: {
      series: ["MVMT"],
    },
    exclude: {
      footring: ["XF"],
    },
  },
  {
    title: "Forward Seat Tilt Adjustment",
    youtubeId: "dFRRhdT7XcI",
    include: {
      series: ["MVMT"],
    },
  },
  {
    title: "Seat / Backrest Micro Tension Adjustment",
    youtubeId: "jws3MswOfEs",
    include: {
      series: ["MVMT"],
    },
  },
  {
    title: "Seat Control Lock Out",
    youtubeId: "3wzkLpRXfkI",
    include: {
      series: ["MVMT"],
    },
  },
  {
    title: "Seat Height Adjustment",
    youtubeId: "YZCPVsISr0k",
    include: {
      series: ["MVMT"],
    },
  },
  {
    title: "Seat Slide Adjustment",
    youtubeId: "-rL6eKqgMg8",
    include: {
      series: ["MVMT"],
    },
  },
  {
    title: "Backrest Height Adjustment",
    youtubeId: "vUZky9zEwIU",
    include: {
      control: ["E1"],
    },
  },
  {
    title: "Backrest Height Adjustment",
    youtubeId: "qyjM-Lkrye8",
    include: {
      control: ["E2"],
    },
  },
  {
    title: "Backrest Height Adjustment",
    youtubeId: "MQuGnIa0LzM",
    include: {
      control: ["E3"],
    },
  },
  {
    title: "Footring Adjustment",
    youtubeId: "1u4xaS--gAo",
    include: {
      control: ["E1"],
    },
    exclude: {
      footring: ["XF"],
    },
  },
  {
    title: "Footring Adjustment",
    youtubeId: "4CfDQjzS5EU",
    include: {
      control: ["E2"],
    },
    exclude: {
      footring: ["XF"],
    },
  },
  {
    title: "Footring Adjustment",
    youtubeId: "k7buaX9g6nU",
    include: {
      control: ["E3"],
    },
    exclude: {
      footring: ["XF"],
    },
  },
  {
    title: "Armrest Adjustment",
    youtubeId: "2aez62mL0lg",
    include: {
      control: ["E1"],
    },
    exclude: {
      arm: ["XA"],
    },
  },
  {
    title: "Armrest Adjustment",
    youtubeId: "_oU6D5o3-W4",
    include: {
      control: ["E2"],
    },
    exclude: {
      arm: ["XA"],
    },
  },
  {
    title: "Armrest Adjustment",
    youtubeId: "xE1R2peFUrw",
    include: {
      control: ["E3"],
    },
    exclude: {
      arm: ["XA"],
    },
  },
  {
    title: "Control Operations",
    youtubeId: "9NUfwg9eAgc",
    include: {
      control: ["E1"],
    },
  },
  {
    title: "Control Operations",
    youtubeId: "J7k3Jg2NVtA",
    include: {
      control: ["E2"],
    },
  },
  {
    title: "Control Operations",
    youtubeId: "WES8UuPff_I",
    include: {
      control: ["E3"],
    },
  },
  {
    title: "Task Control SLD Seat Slider",
    youtubeId: "3vUVsHeOe9Y",
    include: { control: ["T:SLD"] },
  },
  {
    title: "Task Control Seat Height Adjustment",
    youtubeId: "KkZnFMwlD4A",
    include: { control: ["T", "T:SLD"] },
  },
  {
    title: "Task Control Backrest Tilt Adjustment",
    youtubeId: "RqUoe5eurSU",
    include: { control: ["T", "T:SLD"] },
  },
  {
    title: "Task Control Backrest Height Adjustment",
    youtubeId: "cBhbTexMSQI",
    include: { control: ["T", "T:SLD"] },
  },
  {
    title: "EXE Executive Seat Control Seat and Back Tilt Demonstration",
    youtubeId: "QbYYstctb18",
    include: { control: ["EXE", "EXE:SLD"] },
  },
  {
    title: "EXE Executive Seat Control Seat Height Adjustment Demonstration",
    youtubeId: "nVN-k6gS5So",
    include: { control: ["EXE", "EXE:SLD"] },
  },
  {
    title: "EXE Executive Seat Control Seat Tilt Tension Adjustment Demonstration",
    youtubeId: "o7MFBeHKJjQ",
    include: { control: ["EXE", "EXE:SLD"] },
  },
  {
    title: "EXE Executive Seat Control Seat Slider Demonstration",
    youtubeId: "uR0GV7Ka9YY",
    include: { control: ["EXE:SLD"] },
  },
  {
    title: "EXE Executive Seat Control Backrest Tilt Adjustment Demonstration",
    youtubeId: "6gaW9ee-9hI",
    include: { control: ["EXE", "EXE:SLD"] },
  },
  {
    title: "EXE Executive Seat Control Backrest Height Adjustment Demonstration",
    youtubeId: "rNGxT0mTOV4",
    include: { control: ["EXE", "EXE:SLD"] },
  },
  {
    title: "FFAC Free Float Articulating Control Seat Height Adjustment Demonstration",
    youtubeId: "HVCKnz-gokM",
    include: { control: ["FFAC", "FFAC:SLD"] },
  },
  {
    title: "FFAC Free Float Articulating Control Seat Tilt Adjustment Demonstration",
    youtubeId: "_62zTpgTAUk",
    include: { control: ["FFAC", "FFAC:SLD"] },
  },
  {
    title: "FFAC Free Float Articulating Control Backrest Tilt Adjustment Demonstration",
    youtubeId: "QaycM_rlyf4",
    include: { control: ["FFAC", "FFAC:SLD"] },
  },
  {
    title: "FFAC Free Float Articulating Control Backrest Height Adjustment Demonstration",
    youtubeId: "p56dzlBW9BA",
    include: { control: ["FFAC", "FFAC:SLD"] },
  },
  {
    title: "PSAS Heigh Adjustable Armrest Demonstration",
    youtubeId: "bTZvVz-UK5w",
    include: { arm: ["PSAS"] },
  },
  {
    title: "ARS Height and Width Adjustable Armrest Height Adjustment Demonstration",
    youtubeId: "OH-zGEbZM68",
    include: { arm: ["ARS"] },
  },
  {
    title: "ARS Height and Width Adjustable Armrest Width Adjustment Demonstration",
    youtubeId: "8YhcFQUAjYM",
    include: { arm: ["ARS"] },
  },
  {
    title: "ATF Footring Adjustment Demonstration",
    youtubeId: "26vjUwYUcYs",
    include: { footring: ["ATF"] },
  },
];

const assembly = [
  {
    title: "Assembly - Casters/Glides to Base",
    youtubeId: "KMMWpirTrS0",
    include: {
      series: ["MVMT"],
    },
  },
  {
    title: "Assembly - Casters/Glides to Base",
    youtubeId: "fwKOtcIPDIg",
    include: {
      series: ["MTCL"],
    },
  },
  {
    title: "Assembly - Casters/Glides to Base",
    youtubeId: "gLmv-6z-yvY",
    include: {
      series: ["MTHD"],
    },
  },
  {
    title: "Assembly - Pneumatic Post to Base",
    youtubeId: "X-Fe3LphYMY",
    include: {
      series: ["MVMT"],
    },
  },
  {
    title: "Assembly - Pneumatic Post to Base",
    youtubeId: "sXFEx5Matvc",
    include: {
      series: ["MTCL", "MTHD"],
    },
  },
  {
    title: "Assembly - Footring to Pneumatic Post",
    youtubeId: "2Otcqr3rsmI",
    include: {
      series: ["MVMT"],
    },
    exclude: {
      footring: ["XF"],
    },
  },
  {
    title: "Assembly - Footring to Pneumatic Post",
    youtubeId: "Rgtr5lpk9NA",
    include: {
      series: ["MTCL", "MTHD"],
    },
    exclude: {
      footring: ["XF"],
    },
  },
  {
    title: "Assembly - Controls to Pneumatic Post",
    youtubeId: "0jJ0uFuAT70",
    include: {
      series: ["MVMT"],
    },
  },
  {
    title: "Assembly - Controls to Pneumatic Post",
    youtubeId: "ibqIumTn10Y",
    include: {
      series: ["MTCL", "MTHD"],
    },
  },
  {
    title: "Assembly - Backrest to Controls",
    youtubeId: "tk1OChGDyc4",
    include: {
      series: ["MVMT"],
    },
  },
  {
    title: "Assembly - Backrest to Controls",
    youtubeId: "UzbCBCrZ90k",
    include: {
      series: ["MTCL", "MTHD"],
    },
  },
  {
    title: "Foundation Backrest to Control Assembly",
    youtubeId: "xFi7OcpaFog",
    include: {
      style: ["foundation"],
    },
  },
  {
    title: "BioFit Seating Caster Assembly Demonstration",
    youtubeId: "fXq9eGwpyf0",
    include: {
      style: ["foundation"],
    },
  },
  {
    title: "BioFit Seating Control Assembly Demonstration",
    youtubeId: "tQ7_2WZZL7s",
    include: {
      style: ["foundation"],
    },
  },

  {
    title: "BioFit Seating AFP Footring Assembly Demonstration",
    youtubeId: "A-WHHBjQDBE",
    include: { style: ["foundation"] },
    exclude: {
      footring: ["XF"],
    },
  },
  {
    title: "BioFit Seating Pneumatic Seat Height Post Assembly Demonstration",
    youtubeId: "MHlzpmrWXCU",
    include: { style: ["foundation"] },
  },
];

export default {
  operational,
  assembly,
};

export const getVideosForManufactureCode = (code) => {
  const selectedWithoutSeries = buildSelectedFromCode(code);

  const series = detectSeries(selectedWithoutSeries);

  if (!series) {
    return {
      assembly: [],
      operational: [],
    };
  }
  const style = ["MVMT", "MTCL", "MTHD"].includes(series) ? "MVMT" : "foundation";
  const selected = { ...selectedWithoutSeries, series, style };

  const filterVideoList = (list) => {
    const componentKeys = _.keys(selected);
    let videoList = [];

    // Include matching videos
    componentKeys.forEach((component) => {
      videoList = [
        ...videoList,
        ...list.filter((video) => {
          if (!video.include || !video.include[component]) return false;
          return video.include[component].indexOf(selected[component]) !== -1;
        }),
      ];
    });

    // Exclude matching videos
    componentKeys.forEach((component) => {
      videoList = videoList.filter((video) => {
        if (!video.exclude || !video.exclude[component]) return true;
        return video.exclude[component].indexOf(selected[component]) === -1;
      });
    });

    return videoList;
  };

  return {
    assembly: filterVideoList(assembly),
    operational: filterVideoList(operational),
  };
};
