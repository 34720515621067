import yup from "yup";
import { manufactureCodeIsValid } from "../../manufactureCode";

const validateManufactureCode = (code) => manufactureCodeIsValid({ code });

const s = (label) => yup.string().label(label);

const schema = yup.object().shape({
  manufactureCode: s("Manufacture Code")
    .test("manufactureCodeValid", "Manufacture Code is not valid", validateManufactureCode)
    .required(),
  manufactureDate: s("Manufacture Date").matches(
    /^(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])-(19|20|21)\d{2}$/,
    "Manufacture date must be in format MM-DD-YYYY"
  ),
  invoiceNumber: s("Invoice Number"),
});

export default schema;
