import React, { useEffect, useState, useRef } from "react";
import { Flex as OriginalFlex, Box as OriginalBox } from "grid-styled";
import styled, { css } from "styled-components";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  buildSelectedFromCode,
  manufactureCodeIsValid,
  buildManufactureCode,
  selectFirstValidFromSelection,
} from "../../manufactureCode";

import FA from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/fontawesome-free-solid";
import { H1 } from "../TextStyles";
import Options from "./Options";
import Controls from "./Controls";
import GetQuote from "./GetQuote";
import Price from "./Price";
import ComponentDescriptions from "./ComponentDescriptions";
import DescriptionList from "../DescriptionList";
import ChairImage from "../ChairImage";
import { PageContainer } from "../Grid";
import prop65warning from "./images/prop65warning.png";
import Breadcrumb from "../Breadcrumb";
import useWindowDimensions from "../useWindowDimensions";

const printStyle = css`
  @media print {
    display: ${({ printDisplay }) => printDisplay};
  }
`;
const Flex = styled(({ printDisplay, ...rest }) => <OriginalFlex {...rest} />)`
  ${printStyle};
`;

const Box = styled(({ printDisplay, ...rest }) => <OriginalBox {...rest} />)`
  ${printStyle};
`;

const Print = styled.div`
  display: none;
  text-align: center;
  @media print {
    display: block;
  }
`;

const Sticky = Flex.extend`
  position: sticky;
  top: 0;
  align-self: flex-start;
`;

const Prop65Img = styled.img`
  width: 50%;
`;

const ProTip = styled.div`
  font-weight: 400;
  text-align: center;
`;

const filterDescriptions = (selected) => {
  // filter out options we don't want to show

  const filteredSelected = { ...selected };

  const hide = ["market", "style", "upholstered"];
  hide.forEach((type) => delete filteredSelected[type]);
  return filteredSelected;
};

const MobileChairImage = styled.div`
  display: flex;
  @media (min-width: 640px) {
    display: none;
  }
  height: 755px;
`;

const FloatingMobileChairImageStyle = styled.div`
  display: flex;
  @media (min-width: 640px) {
    display: none;
  }
  @media print {
    display: none;
  }
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 200px;
  z-index: 3;
  justify-content: center;
  align-items: flex-end;
`;

const FloatingMobileChairImageInner = styled.div`
  position: relative;
  /* bottom: 200px; */
  width: 125px;
  background-color: #f2f2f2;
  box-shadow: 0 0 15px #ccc;
  align-self: flex-start;
  height: 200px;
`;

const ViewChairButton = styled.div`
  color: white;
  background: ${({ theme }) => theme.primary};
  color: white;
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
`;

// Hook
function useOnScreen(ref, rootMargin = "0px") {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    const copiedRef = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    if (ref.current) {
      observer.observe(copiedRef);
    }
    return () => {
      if (copiedRef) observer.unobserve(copiedRef);
    };
  }, [ref, rootMargin]); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
}

const FloatingMobileChairImage = ({ children, mobileImageRef }) => {
  const [show, setShow] = useState(true);
  const onScreen = useOnScreen(mobileImageRef, "-200px");
  if (onScreen) return null;
  return (
    <FloatingMobileChairImageStyle>
      {show ? (
        <React.Fragment>
          <div style={{ alignSelf: "flex-start", position: "relative" }}>
            <FA
              style={{
                position: "absolute",
                zIndex: "2",
                top: "-5px",
                left: "-5px",
              }}
              icon={faTimesCircle}
              onClick={() => setShow(false)}
            />
          </div>

          <FloatingMobileChairImageInner>{children}</FloatingMobileChairImageInner>
        </React.Fragment>
      ) : (
        <ViewChairButton onClick={() => setShow(true)}>View Chair</ViewChairButton>
      )}
    </FloatingMobileChairImageStyle>
  );
};

const Builder = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const mobileImageRef = useRef();
  const { height } = useWindowDimensions();

  // Chair angle functions
  const [angle, setAngle] = useState(0);
  const turnLeft = () => {
    setAngle(angle > 4 ? 0 : angle + 1);
  };
  const turnRight = () => {
    setAngle(angle < 1 ? 5 : angle - 1);
  };

  // add the state to the query string so that the page can be bookmarked / shared
  const qs = queryString.parse(location.search);
  const newQs = `?${queryString.stringify({ ...qs, ...location.state })}`;

  // useEffect(() => {
  if (newQs !== location.search) {
    // console.log("newQqs !== location.search :>> ", newQs, location.search);
    location.search = newQs;
    history.replace(location);
    return null;
  }
  // }, []);

  const { manufactureCode } = params;
  // Route to default if there is no manufacture code, or it is invalid
  const routeToDefault = () => {
    location.pathname = "/build";
    history.replace(location);
    return null;
  };
  // if (!manufactureCode) return routeToDefault();
  const { market, style, upholstered, series } = qs || {};

  let selected = buildSelectedFromCode(manufactureCode, { market, style, upholstered, series });

  // console.log("{...selected} :>> ", { ...selected });

  selected = selectFirstValidFromSelection(selected);

  if (!manufactureCodeIsValid({ selected })) {
    console.log(`manufactureCode ${manufactureCode} is not valid.`, { ...selected });
    return routeToDefault();
  }

  // Could get a transformed code after building the code because of older codes being converted
  // if so re-route the user using the new code
  const possiblyNewCode = buildManufactureCode(selected);
  if (possiblyNewCode !== manufactureCode) {
    location.pathname = `/build/${possiblyNewCode}`;
    history.replace(location);
    return null;
  }

  selected.series = qs.series || "X";

  return (
    <PageContainer flexWrap="wrap">
      <Helmet>
        <title>Build My Chair</title>
      </Helmet>
      <Breadcrumb />
      <H1>Build Your Custom Chair</H1>
      <Box printDisplay="none" my="1rem">
        Get the chair that’s designed for your body — and built for your workspace. Select the
        options that fit your specifications on the left, and then get a quote for the BioFit
        seating that’s right for you!
      </Box>
      <Flex printDisplay="none" flexWrap="wrap" style={{ overflowY: "clip" }}>
        <Box width={[1, 425]}>
          <Options selected={selected} />
          <MobileChairImage innerRef={mobileImageRef}>
            <ChairImage angle={angle} selected={selected} />
          </MobileChairImage>
          <Controls
            manufactureCode={manufactureCode}
            history={history}
            turnLeft={turnLeft}
            turnRight={turnRight}
          />
          <Box width={1}>
            <Price selected={selected} />
            <GetQuote manufactureCode={manufactureCode} selected={selected} />
            <ProTip>
              PRO TIP: To save your spot in the builder, bookmark this page by clicking the share
              button above.
            </ProTip>
          </Box>
        </Box>

        <Sticky alignItems="center" justifyContent="center" flex={1}>
          <ChairImage angle={angle} selected={selected} maxHeight={`${height}px`} />
        </Sticky>
      </Flex>
      <FloatingMobileChairImage mobileImageRef={mobileImageRef}>
        <ChairImage angle={angle} selected={selected} />
      </FloatingMobileChairImage>

      <Box printDisplay="none" width={1}>
        <ComponentDescriptions selected={filterDescriptions(selected)} />
      </Box>
      <Print>
        <ChairImage angle={angle} selected={selected} maxHeight="600px" />
        <div>{manufactureCode}</div>
        <div>{window.location.href}</div>
        <Price selected={selected} />
        <DescriptionList selected={selected} />
        <Prop65Img
          src={prop65warning}
          alt={`WARNING: This product can expose you to chemicals including wood dust, which is known to the State
            of California to cause cancer. For more information go to www.P65Warnings.ca.gov`}
        />
      </Print>
    </PageContainer>
  );
};

export default Builder;
