import MVMT from "./MVMT.png";
import MTCL from "./MTCL.png";
import MTHD from "./MTHD.png";
import AE from "./AE.png";
import AM from "./AM.png";
import BE from "./BE.png";
import BT from "./BT.png";
import EB from "./EB.png";
import EE from "./EE.png";
import ET from "./ET.png";
import FC from "./FC.png";
import FL from "./FL.png";
import HC from "./HC.png";
import HL from "./HL.png";
import VF_FC from "./VF_FC.png";
import VF_FL from "./VF_FL.png";
import VF_HC from "./VF_HC.png";
import VF_HL from "./VF_HL.png";
import Z from "./Z.png";
import GG from "./GG.png";
import UU from "./UU.png";
import KX from "./KX.png";
import WW from "./WW.png";
import IP from "./IP.png";
import TWOA from "./2A.png";
import RX from "./RX.png";
import VF_RX from "./VF_RX.png";
import RT from "./RT.png";
import VF_RT from "./VF_RT.png";
import TX from "./TX.png";
import CX from "./CX.png";
import GX from "./GX.png";
import AJ from "./AJ.png";
import TXG from "./TXG.png";
import Scepter from "./Scepter.png";

export default {
  MVMT,
  MTCL,
  MTHD,
  AE,
  AM,
  BE,
  BT,
  EB,
  EE,
  ET,
  FC,
  FL,
  HC,
  HL,
  VF_FC,
  VF_FL,
  VF_HC,
  VF_HL,
  Z,
  GG,
  UU,
  KX,
  WW,
  IP,
  TWOA,
  RX,
  VF_RX,
  RT,
  VF_RT,
  TX,
  CX,
  GX,
  AJ,
  TXG,
  Scepter,
};
