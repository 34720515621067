import * as yup from "yup";
import { withProps } from "recompose";

const withFieldProps = withProps(({ name: propName, field, form, label, schema }) => {
  // { name, value, onChange, onBlur } // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  const { name: fieldName } = field || {};
  const { touched, errors, values } = form || {};
  const name = propName || fieldName;
  let fieldLabel = label;
  let tests = [];
  let schemaLabel;
  if (schema) {
    ({ label: schemaLabel, tests } = yup.reach(schema, name).describe());
  }

  if (fieldLabel === undefined) fieldLabel = schemaLabel;
  const touch = touched[name];
  const error = touch ? errors[name] : null;
  const required = tests.indexOf("required") !== -1;

  return {
    label: fieldLabel,
    touch,
    error,
    name,
    required,
    values,
    field,
    form,
  };
});

export default withFieldProps;
