import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Layout from "./Layout";
import ScrollToTop from "./ScrollToTop.jsx";
import GAHandler from "./GAHandler.jsx";
// import ImageList from "./ImageList";
import Home from "./Home";
import Builder from "./Builder";
import FindChair from "./FindChair";
import ChairDetails from "./ChairDetails";
import TableDetails from "./TableDetails";
import WarrantyRequest from "./WarrantyRequest";
import WarrantyRequestSubmit from "./WarrantyRequest/Submit";
import WarrantyRequestThanks from "./WarrantyRequest/Thanks";
import Market from "./Market";
import SelectStyle from "./SelectStyle";
import SelectSeries from "./SelectSeries";
import SelectUpholstery from "./SelectUpholstery";
import EnablePricingRoute from "./Pricing/EnablePricingRoute";
const Routes = () => (
  <Router>
    <GAHandler>
      <ScrollToTop>
        <Layout>
          <Route path="/" exact component={Home} />
          <Route path="/market" exact component={Market} />
          <Route path="/style" exact component={SelectStyle} />
          <Route path="/series" exact component={SelectSeries} />
          <Route path="/upholstery" exact component={SelectUpholstery} />
          <Route path="/build/:manufactureCode?" component={Builder} />
          <Route path="/find-chair" component={FindChair} />
          <Route
            path="/chair/:manufactureCode/:invoiceNumber?/:manufactureDate?"
            component={ChairDetails}
          />
          <Route
            path="/warranty-request/:manufactureCode/:invoiceNumber?/:manufactureDate?"
            component={WarrantyRequest}
          />
          <Route
            path="/warranty-request-submit/:manufactureCode/:invoiceNumber?/:manufactureDate?"
            component={WarrantyRequestSubmit}
          />
          <Route path="/warranty-request-thanks" component={WarrantyRequestThanks} />

          <Route path="/table/:manufactureCode/:manufactureDate?" component={TableDetails} />

          <Route component={EnablePricingRoute} />

          {/* <Route path="/imageList/:section?" component={ImageList} /> */}
        </Layout>
      </ScrollToTop>
    </GAHandler>
  </Router>
);

export default Routes;
