import React from "react";
import { Field } from "formik";
import styled from "styled-components";
import withFieldProps from "./withFieldProps";
import { FieldWrap, Label, FieldError } from "./Styles";

const RadioGroupWrapper = styled.div`
  margin: 0.5rem 0;
`;

const RadioGroup = (props) => {
  const { required, label, options = [], name, field, error, afterChange } = props;
  return (
    <FieldWrap>
      {label ? <Label required={required}>{label}</Label> : null}
      <RadioGroupWrapper>
        {options.map((option) => {
          const id = option._id || option.id || option;
          return (
            <div key={id}>
              <label htmlFor={id}>
                <input
                  {...field}
                  name={name}
                  type="radio"
                  value={id}
                  id={id}
                  checked={field.value === id}
                  onChange={(...props) => {
                    field.onChange(...props);
                    if (afterChange) afterChange(...props);
                  }}
                />{" "}
                {option.name || option}
              </label>
            </div>
          );
        })}
      </RadioGroupWrapper>
      {error ? <FieldError>{error}</FieldError> : null}
    </FieldWrap>
  );
};

const Enhanced = withFieldProps(RadioGroup);
const Radios = (props) => <Field {...props} component={Enhanced} />;
export default Radios;
