import _ from "lodash";
import React from "react";
import styled from "styled-components";
import FA from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/fontawesome-free-solid";
import getImageNames from "../getImageNames";
import { getMvmtSeries, fromCodeToMvmtCode } from "../manufactureCode";

const { REACT_APP_IMAGES_URL: imageUrl } = process.env;

const ChairLayers = styled.div`
  position: relative;
  z-index: 0;
  max-width: 489px;
  ${"" /* display: flex; */} width: 100%;
  height: ${({ maxHeight }) => maxHeight || "100%"};
  ${"" /* justify-content: center; */};
`;

const HalfImg = styled.img`
  position: absolute;
  max-height: ${({ maxHeight }) => maxHeight || "825px"};
  z-index: ${({ zIndex }) => zIndex};
`;

const Loading = () => (
  <div>
    <FA icon={faCog} spin size="4x" />
  </div>
);

const getImagePaths = (props) => {
  const { selected, angle } = props;
  const images = getImageNames(selected);
  const { upper, lower } = images || {};

  const anglePadded = _.padStart(angle, 3, "0");
  return {
    upper: upper ? `${imageUrl}${upper}-${anglePadded}.png` : null,
    lower: lower ? `${imageUrl}${lower}-${anglePadded}.png` : null,
  };
};

class ChairImage extends React.Component {
  constructor(props) {
    super(props);
    const { upper, lower } = getImagePaths(props);

    this.state = {
      imageError: false,
      upperLoading: true,
      lowerLoading: true,
      upper,
      lower,
    };

    this.handleImageError = this.handleImageError.bind(this);
    this.handleLoaded = this.handleLoaded.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { upper, lower } = getImagePaths(nextProps);

    this.setState({
      imageError: false,
      upperLoading: upper !== this.state.upper ? true : false,
      lowerLoading: lower !== this.state.lower ? true : false,
      upper,
      lower,
    });
  }

  handleImageError() {
    this.setState({ imageError: true, upperLoading: false, lowerLoading: false });
  }

  handleLoaded(half, { upper, lower }) {
    if (!upper || half === "upper") this.setState({ upperLoading: false });
    if (!lower || half === "lower") this.setState({ lowerLoading: false });
  }

  render() {
    const { selected, maxHeight } = this.props;
    const { imageError, upperLoading, lowerLoading, upper, lower } = this.state;

    const loading = upperLoading || lowerLoading;

    if (imageError || !selected)
      return (
        <div>
          Image not available {upper} & {lower}
        </div>
      );
    //
    // const images = getImageNames(selected);
    // const { upper, lower } = images || {};

    const series = getMvmtSeries(fromCodeToMvmtCode(selected));
    const { back } = selected || {};
    let inlineShift = null;
    if (series === "MTCL") inlineShift = { top: "-20px" };
    if (back === "S") inlineShift = { top: "55px" };

    return (
      <React.Fragment>
        {loading ? <Loading /> : null}
        <ChairLayers maxHeight={maxHeight} style={loading ? { display: "none" } : null}>
          {upper ? (
            <HalfImg
              onError={this.handleImageError}
              onLoad={() => this.handleLoaded("upper", { upper, lower })}
              zIndex="1"
              src={upper}
              maxHeight={maxHeight}
            />
          ) : null}
          {lower ? (
            <HalfImg
              onError={this.handleImageError}
              onLoad={() => this.handleLoaded("lower", { upper, lower })}
              zIndex="0"
              style={inlineShift}
              src={lower}
              maxHeight={maxHeight}
            />
          ) : null}
        </ChairLayers>
      </React.Fragment>
    );
  }
}

export default ChairImage;
