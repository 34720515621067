import _ from "lodash";
import { withStateHandlers } from "recompose";

const withToggle = (options) => {
  const opts = {
    name: "state", //share
    trueName: "on", // open
    falseName: "off", // close
    toggleName: "toggle",
    initialState: false,
    ...options,
  };

  const upperName = _.upperFirst(opts.name);
  const upperTrue = _.upperFirst(opts.trueName);
  const upperFalse = _.upperFirst(opts.falseName);

  const stateName = `${opts.name}${upperTrue}`;
  const trueFn = `set${upperName}${upperTrue}`;
  const falseFn = `set${upperName}${upperFalse}`;
  const toggleFn = `${opts.toggleName}${upperName}${upperTrue}`;

  return withStateHandlers(
    { [stateName]: opts.initialState },
    {
      [trueFn]: () => () => ({ [stateName]: true }),
      [falseFn]: () => () => ({ [stateName]: false }),
      [toggleFn]: (state) => () => ({ [stateName]: !state[stateName] }),
    }
  );
};

export default withToggle;
