import seatComponents from "../seatComponents";

// Always show 3 controls for MTCL regardless of seat
const mvmtControls = ({ selected, options: initialOptions }) => {
  let options = initialOptions;
  const { series } = selected;
  if (series !== "MTCL") return { selected, options };

  // build list of 3 control options

  const [allControls] = seatComponents.filter(({ type }) => type === "control");
  const controls = allControls.data.filter(({ code }) => ["T", "FFAC", "E3"].indexOf(code) !== -1);

  options = options.map((option) => {
    const { type } = option;
    if (type === "control") return { ...option, data: controls };
    return option;
  });

  return { selected, options };
};

export default mvmtControls;
