import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import usePricing from "./usePricing";
import { useEffect } from "react";

const EnablePricingRoute = () => {
  const location = useLocation();
  const history = useHistory();
  const { validateAndStoreToken } = usePricing();

  const queryParams = queryString.parse(location.search);
  const { pricingToken } = queryParams || {};

  useEffect(() => {
    if (pricingToken) {
      validateAndStoreToken(pricingToken);
      history.replace({
        search: queryString.stringify({ ...queryParams, pricingToken: undefined }),
      });
    }
  });

  return null;
};

export default EnablePricingRoute;
