import React from "react";
import { Formik, Form } from "formik";
import { Flex, Box } from "grid-styled";
import { withProps } from "recompose";
import queryString from "query-string";
import FA from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/fontawesome-free-solid";
import { Helmet } from "react-helmet";
import { PageContainer } from "../Grid";
import { H1 } from "../TextStyles";
import { LargeButton } from "../Button";
import { Text, Textarea } from "../Forms";
import { submitSchema as schema } from "./schema";
import notifications from "../notifications";
import APIClient from "../../apiClient";

const apiClient = new APIClient();

const TextField = withProps({ schema })(Text);
const TextareaField = withProps({ schema })(Textarea);

const GetQuoteForm = (props) => {
  const { isSubmitting } = props;
  return (
    <Form>
      <Flex flexWrap="wrap">
        <Box px={2} width={[1, 1 / 2]}>
          <TextField name="firstName" />
          <TextField name="lastName" />
          <TextField name="businessName" />
          <TextField name="phone" />
          <TextField name="email" />
          <TextareaField name="additionalNotes" />
        </Box>
        <Box px={2} width={[1, 1 / 2]}>
          <TextField name="addressLine1" />
          <TextField name="addressLine2" />
          <TextField name="city" />
          <TextField name="state" />
          <TextField name="zipCode" />
          <TextField name="country" />
          <TextField disabled name="manufactureCode" />
          <Box m="2rem">
            <LargeButton active type="submit" disabled={isSubmitting}>
              <FA icon={faCheck} /> Submit
            </LargeButton>
          </Box>
        </Box>
      </Flex>
    </Form>
  );
};

const WarrantySubmit = ({ match, location, history }) => {
  const { params } = match || {};
  const { manufactureCode, invoiceNumber, manufactureDate } = params;
  const { selectedParts: selectedPartsEncoded } = queryString.parse(location.search);
  const selectedParts = JSON.parse(atob(selectedPartsEncoded));
  return (
    <PageContainer>
      <Helmet>
        <title>Warranty Issue</title>
      </Helmet>
      <H1>Submit Warranty Issue</H1>
      <div>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            businessName: "",
            phone: "",
            email: "",
            additionalNotes: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
            manufactureCode,
            invoiceNumber,
            manufactureDate,
            selectedParts,
          }}
          validationSchema={schema}
          onSubmit={async (values) => {
            return apiClient
              .fetch("sendEmail", {
                body: {
                  type: "warrantyRequest",
                  formData: values,
                },
              })
              .then(() => {
                history.push("/warranty-request-thanks");
              })
              .catch((error) => notifications({ error: "Error submitting warranty request." }));
          }}
          component={GetQuoteForm}
        />
      </div>
    </PageContainer>
  );
};

export default WarrantySubmit;
