import React from "react";
import { Box, Flex } from "grid-styled";

const ContainerNoPadding = Box.extend`
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

export const Row = (props) => <Flex mx={-2} {...props} />;

export const Column = (props) => <Box px={2} flex="1 1 auto" {...props} />;

export const Container = (props) => <ContainerNoPadding px={[2, 3]} {...props} />;
export const PageContainer = (props) => (
  <ContainerNoPadding px={[2, 3]} pt="2rem" pb="4rem" {...props} />
);
