import fetch from "cross-fetch";

class APIClient {
  constructor() {
    this._apiBase = process.env.REACT_APP_BIOFIT_API_ENDPOINT;
    const key = process.env.REACT_APP_BIOFIT_API_KEY;

    if (!key) {
      this.disconnected = true;
      throw new Error("Error connecting to API, missing api key.");
    }

    this.fetchOptions = {
      method: "post",
      headers: {
        "X-Api-Key": key,
        "Content-Type": "application/json",
      },
      timeout: 5000, // Timeout after 5s
    };
  }
  async fetch(endpoint, options) {
    if (this.disconnected) return false;
    let url = `${this._apiBase}/${endpoint}`;
    if (options.fullUrl) url = endpoint;
    options.body = JSON.stringify(options.body);

    const res = await fetch(url, { ...this.fetchOptions, ...options });
    if (res.status >= 400) {
      throw new Error("Bad response from server");
    }
    return res;
  }
}

export default APIClient;
