export default [
  {
    code: "upholstered",
    name: "Upholstered",
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "R", "VF:R"],
    series: [
      "MVMT",
      "MTCL",
      "MTHD",
      "2A",
      "AM",
      "BT",
      "EE",
      "EB",
      "FC",
      "FL",
      "HC",
      "HL",
      "IP",
      "RT",
      "RX",
      "Scepter",
      "Z",
    ],
  },
  {
    code: "nonUpholstered",
    name: "Non-Upholstered",
    seat: ["U", "K", "G", "W", "T", "C", "TX", "L"],
    series: ["CX", "GG", "GX", "KX", "TX", "TXG", "UU", "WW", "AJ"],
  },
];
