import React from "react";
import { Link } from "react-router-dom";
import { Flex, Box } from "grid-styled";
import { Helmet } from "react-helmet";
import { PageContainer } from "../Grid";
import { H1 } from "../TextStyles";
import { LargeButton } from "../Button";

const Message = Box.extend`
  text-align: center;
`;

const Home = () => (
  <PageContainer>
    <Helmet>
      <title>Warranty Request Submitted</title>
    </Helmet>
    <Flex flexWrap="wrap" justifyContent="center">
      <Message width={1}>
        <H1>Warranty Request Submitted</H1>
      </Message>

      <Message width={[1, 1 / 2]}>
        <p>
          We have received your warranty request. A BioFit representative will be in touch with you
          soon.
        </p>

        <Link to="/">
          <LargeButton> Return Home </LargeButton>
        </Link>
      </Message>
    </Flex>
  </PageContainer>
);

export default Home;
