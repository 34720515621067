import yup from "yup";
import { manufactureCodeIsValid } from "../../manufactureCode";

const a = (label) => yup.array().of(yup.string()).label(label).nullable();

export const selectedPartsSchema = yup.object().shape({
  selectedParts: a("Selected Parts").required(),
});

const validateManufactureCode = (code) => manufactureCodeIsValid({ code });

const s = (label) => yup.string().required().label(label);

export const submitSchema = yup.object().shape({
  firstName: s("First Name"),
  lastName: s("Last Name"),
  businessName: s("Business / Institution Name"),
  phone: s("Phone Number"),
  email: s("Email Address").email(),
  additionalNotes: yup.string().label("Additional Notes"),
  addressLine1: s("Address Line 1"),
  addressLine2: yup.string().label("Address Line 2"),
  city: s("City"),
  state: s("State"),
  zipCode: s("Zip Code"),
  country: s("Country"),
  manufactureCode: s("Manufacture Code").test(
    "manufactureCodeValid",
    "Manufacture Code is not valid",
    validateManufactureCode
  ),
  selectedParts: a("Selected Parts").required(),
  manufactureDate: yup.string().label("Manufacture Date"),
  invoiceNumber: yup.string().label("Invoice Number"),
});
