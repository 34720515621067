const sldRule = ({ selected, options }) => {
  let filteredOptions = options;
  // Rule: T:SLD & FFAC:SLD + A or R base is not available with seat height is Medium or High, only available in W
  const { control, base, height } = selected || {};
  const restrictControl = ["T:SLD", "FFAC:SLD"];
  const restrictHeights = ["M", "H"];
  const restrictBase = ["A", "R"];

  if (restrictControl.indexOf(control) !== -1 && restrictHeights.indexOf(height) !== -1) {
    // remove A and R base (only allow W)
    filteredOptions = filteredOptions.map(({ type, data, ...rest }) => {
      if (type === "base") {
        data = data.filter(({ code }) => code === "W");
      }
      return {
        type,
        data,
        ...rest,
      };
    });
  }
  if (restrictControl.indexOf(control) !== -1 && restrictBase.indexOf(base) !== -1) {
    // only allow Low height
    filteredOptions = filteredOptions.map(({ type, data, ...rest }) => {
      if (type === "height") {
        data = data.filter(({ code }) => code === "L");
      }
      return {
        type,
        data,
        ...rest,
      };
    });
  }
  if (restrictHeights.indexOf(height) !== -1 && restrictBase.indexOf(base) !== -1) {
    // remove T:SLD & FFAC:SLD
    filteredOptions = filteredOptions.map(({ type, data, ...rest }) => {
      if (type === "control") {
        data = data.filter(({ code }) => code !== "T:SLD" && code !== "FFAC:SLD");
      }
      return {
        type,
        data,
        ...rest,
      };
    });
  }

  return { selected, options: filteredOptions };
};

export default sldRule;
