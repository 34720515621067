import ALL from "./ALL";

export default [
  {
    code: "education",
    name: "Education",
    description:
      "Versatile and rugged ergonomic seating designed for today’s K-12 and collegiate learning environments and pedagogies ‒ live, hybrid and beyond.",
    performance: ["06", "C"],
    series: ALL,
  },
  {
    code: "industrial",
    name: "Industrial",
    description:
      "Brainy and brawny seating that works as hard as its users, including advanced innovations and proven performers that stand up to harsh environments.",
    performance: ["06", "C"],
    series: ALL,
  },
  {
    code: "laboratory",
    name: "Laboratory",
    description:
      "Revolutionary advancements that address the unique postures and motions of science professionals to time-honored multidiscipline ergonomic designs.",
    performance: ["06", "C"],
    series: ALL,
  },
  {
    code: "cleanRoom",
    name: "Clean Room",
    description:
      "Precision designed and tested models featuring special components, materials, finishes and construction techniques to meet ISO classes 3 through 8.",
    performance: ["ISO8", "ISO7", "ISO6", "ISO5", "ISO4", "ISO3", "ISO8C", "ISO7C"],
    series: [
      "MVMT",
      "MTCL",
      "MTHD",
      "AM",
      "BT",
      "EE",
      "VF:FC",
      "VF:FL",
      "VF:HC",
      "VF:HL",
      "UU",
      "GG",
      "KX",
      "AJ",
      "TX",
      "TXG",
      "CX",
    ],
  },
  {
    code: "staticControl",
    name: "Static Control",
    description:
      "Seating designed and constructed to ground the user and the chair, safely controlling electrostatic discharge and tested to meet ANSI/ESD STM 12.1",
    performance: ["K"],
    series: [
      "MVMT",
      "MTCL",
      "MTHD",
      "AM",
      "BT",
      "EE",
      "FC",
      "FL",
      "HC",
      "HL",
      "UU",
      "GG",
      "RT",
      "RX",
      "TX",
      "TXG",
    ],
  },
  {
    code: "cleanRoomStaticControl",
    name: "Clean Room / Static Control",
    description:
      "For cleanroom and ESD applications ranging from ISO 3 to ISO 8; features the industry's only phthalate- and fire-retardant-chemical-free vinyl upholstery.",
    performance: ["ISO8K", "ISO7K", "ISO6K", "ISO5K", "ISO4K", "ISO3K"],
    series: ["MVMT", "MTCL", "MTHD", "AM", "BT", "EE", "UU", "GG", "TX", "TXG"],
  },
  {
    code: "healthcare",
    name: "Healthcare",
    description:
      "Models engineered to promote the comfort and well-being of front-line professionals in the ER, intake, X-ray and other medical and caregiving settings.",
    performance: ["06", "C"],
    series: ALL,
  },
  {
    code: "office",
    name: "Office",
    description:
      "Improving the workplace experience from the corporate environment to the home office though impactful design, ergonomic performance and style.",
    performance: ["06", "C"],
    series: ALL,
  },
];
