import _ from "lodash";
import ALL from "./ALL";
import seats from "./seats";
import backs from "./backs";
import bases from "./bases";
import heights from "./heights";
import casters from "./casters";
import controls from "./controls";
import footrings from "./footrings";
import arms from "./arms";
import performance from "./performance";
import vinylUnderwrap from "./vinylUnderwrap";
import memoryFoam from "./memoryFoam";
import series from "./series";
import upholstery from "./upholstery";
import markets from "./markets";
import upholstered from "./upholstered";
import style from "./style";

const componentMaps = [
  { type: "market", name: "Market", data: markets },
  { type: "upholstered", name: "Upholstered", data: upholstered },
  { type: "style", name: "Style", data: style },
  { type: "series", name: "Series", data: series },
  {
    type: "seat",
    name: "Seat",
    data: seats,
  },
  {
    type: "back",
    name: "Backrest",
    data: backs,
  },
  {
    type: "base",
    name: "Base",
    data: bases,
  },
  {
    type: "performance",
    name: "Performance Package",
    data: performance,
  },

  {
    type: "height",
    name: "Seat Height",
    data: heights,
  },
  {
    type: "casters",
    name: "Casters / Glides",
    data: casters,
  },
  {
    type: "control",
    name: "Controls",
    data: controls,
  },
  {
    type: "footring",
    name: "Footring",
    data: footrings,
  },
  {
    type: "arm",
    name: "Arms",
    data: arms,
  },
  {
    type: "vinylUnderwrap",
    name: "Under Seat Upholstery",
    data: vinylUnderwrap,
  },
  {
    type: "memoryFoam",
    name: "Memory Foam",
    data: memoryFoam,
  },
  {
    type: "upholstery",
    name: "Upholstery",
    data: upholstery,
  },
];

// console.log(series.map(({ code }) => code));

const types = componentMaps.map(({ type }) => type);
const defaultSet = {};
types.forEach((type) => {
  defaultSet[type] = ALL;
});

// Convert any ALL values to the full options of a component
const computedComponentMaps = componentMaps.map(({ type, name, data }) => {
  const computedData = data.map((_set) => {
    // add ALL as a default for any missing types in each component set
    const set = { ...defaultSet, ..._set };
    delete set[type];

    _.forOwn(set, (val, setType) => {
      // if (setType === "code") {
      //   code = val;
      //   return;
      // }
      if (
        [
          "code",
          "name",
          "description",
          "dynamicDescription",
          "dynamicName",
          "mvmtCode",
          "formerly",
          "price",
        ].indexOf(setType) !== -1
      )
        return;

      const allOptions = componentMaps
        .filter(({ type: typeCompare }) => typeCompare === setType)[0]
        .data.map(({ code }) => code)
        .sort();

      if (val === ALL) {
        set[setType] = allOptions;
      } else {
        if (_.isEqual(val.sort(), allOptions))
          console.log(`${type}.${set.code}.${setType} can use ALL`);
      }
      // Always include series.X as an option.
      if (setType === "series") {
        if (set.series.indexOf("X") === -1) set.series.push("X");
      }
    });
    return set;
  });

  return {
    type,
    name,
    data: computedData,
  };
});

// Check each component for non matching counterpart components
// computedComponentMaps.forEach(({ type, name, data }) => {
//   let code;
//   data.forEach(set => {
//     _.forOwn(set, (val, setType) => {
//       if (setType === "code") {
//         code = val;
//         return;
//       }
//       if (
//         [
//           "name",
//           "description",
//           "dynamicDescription",
//           "dynamicName",
//           "mvmtCode",
//           "formerly"
//         ].indexOf(setType) !== -1
//       )
//         return;

//       const compareSet = computedComponentMaps.filter(
//         ({ type: typeCompare }) => typeCompare === setType
//       )[0].data;

//       val.forEach(v => {
//         if (
//           compareSet.filter(({ code: codeCompare }) => codeCompare === v)[0][type].indexOf(code) ===
//           -1
//         ) {
//           console.warn(`${setType}.${v}.${type} missing ${code}`);
//         }
//       });
//     });
//   });
// });

// console.log("computedComponentMaps :>> ", computedComponentMaps);

// console.log(
//   "computedComponentMaps :>> ",
//   JSON.stringify(
//     computedComponentMaps.find(({ type }) => type === "series").data.map(({ code }) => code),
//     null,
//     2
//   )
// );

export default computedComponentMaps;
