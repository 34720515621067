import ALL from "./ALL";
import footringDescription from "./footringDescription";
import footringName from "./footringName";

export default [
  {
    code: "XF",
    name: "No Footring",
    dynamicName: (selected) => footringName({ selected }),
    description: "",
    dynamicDescription: (selected) => footringDescription({ selected }),
    seat: ALL,
    back: ALL,
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    arm: ALL,
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
  },
  {
    code: "AFP",
    name: "AFP",
    description: `22" dia. aluminum foot ring includes easy-to-operate hub – push down top of center bushing, adjust to
    desired height, and release to lock the foot ring into place. 22" flattened tubing ring is attached to the cast
    aluminum spokes with stainless steel screws, which utilize a T-cross section to provide a clean, cohesive look for
    models with aluminum (A / P25), reinforced composite (R / GR) or wide aluminum (W / P28) bases. Recommended for
    models with seat heights greater than 22 inches.`,
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "U", "G", "W", "K", "T", "C", "R", "VF:R"],
    back: ["MB", "TB", "E", "M", "T", "C", "L", "U", "G", "W", "X", "P", "B"],
    base: ["A", "R", "W"],
    height: ["M", "H"],
    casters: ["RC", "HG", "RK"],
    control: ["PM", "E3", "T", "FFAC", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD", "S", "Z", "P"],
    arm: ["3D", "ARS", "PSAS", "XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    price: 158,
  },
  {
    code: "ATF",
    name: "ATF - Adjustable Tubular Footring",
    description: `Easily raise or lower this 20-inch diameter cast aluminum footring by loosening and retightening its
    manual adjustment knob. Recommended for models with with aluminum (A), reinforced composite (R) or wide aluminum
    (W) bases with seat heights greater than 22 inches.`,
    seat: ["A", "B", "E", "F", "H", "U", "G", "W", "K", "T", "C", "R", "VF:R"],
    back: ["E", "M", "T", "C", "L", "U", "G", "W", "X", "P", "B"],
    base: ["A", "R", "W"],
    height: ["M", "H"],
    casters: ["RC", "HG", "RK"],
    control: ["T", "FFAC", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD", "S", "Z", "P"],
    arm: ["ARS", "PSAS", "XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    price: 112,
  },
];
