import styled, { css } from "styled-components";

export const FieldWrap = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;
export const Label = styled.label`
  color: ${({ theme }) => theme.primary};
  font-weight: 400;
  ${({ required }) =>
    required &&
    css`
      ::after {
        content: "*";
        padding-left: 2px;
      }
    `};
`;

export const FieldError = styled.div`
  color: red;
  margin-bottom: 1rem;
`;
