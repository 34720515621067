import React from "react";
import styled from "styled-components";
import moment from "moment";
import EnablePricingFooter from "./Pricing/EnablePricingFooter";

const StyledFooter = styled.footer`
  padding: 2rem 0;
  border-top: 1px solid #dedede;
  text-align: center;
  color: #aaa;
  background: white;
  font-size: 0.8rem;
`;

const Footer = () => (
  <>
    <StyledFooter>
      <div>© {moment().format("YYYY")}. BioFit Engineered Products. All rights reserved.</div>
      <EnablePricingFooter />
    </StyledFooter>
  </>
);

export default Footer;
