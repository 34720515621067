import React from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";

const Main = styled.div`
  background-color: #f2f2f2;
`;

const Layout = ({ children }) => (
  <div>
    <Header />
    <Main>{children}</Main>
    <Footer />
  </div>
);
export default Layout;
