import ALL from "./ALL";

export default [
  {
    code: "MB",
    name: "MB - MVMT Medium",
    description: `Backrest height adjustment feature allows for adjustment up or down 3", quickly and securely
      (without the use of a noisy ratchet mechanism). Back measures 17.5" wide by 15" tall with 3/8" thick (5-ply) back
      board and 1.5" foam thickness. 4mm (5/32") thick polypropylene back panel with edge guard.`,
    seat: ["1", "1F", "1B"],
    base: ["A", "R", "W", "S", "TUH"],
    height: ALL,
    casters: ["RC", "HG", "RK"],
    control: ["PM", "T", "FFAC", "E3"],
    footring: ["AFP", "XF"],
    arm: ["3D", "XA"],
    performance: [
      "K",
      "ISO6",
      "ISO5",
      "ISO3",
      "ISO6K",
      "ISO5K",
      "ISO3K",
      "C",
      "06",
      "WD",
      "WDK",
      "ISO8",
      "ISO8C",
      "ISO8K",
      "ISO7",
      "ISO7C",
      "ISO7K",
    ],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["MVMT", "MTCL", "MTHD"],
    price: 491,
  },
  {
    code: "TB",
    name: "TB - MVMT Tall",
    description: `Backrest height adjustment feature allows for adjustment up or down 3", quickly and securely
      (without the use of a noisy ratchet mechanism). Back measures 17.5" wide by 19.5" tall with 3/8" thick (5-ply)
      back board and 1 1/2" foam thickness. 4mm (5/32") thick polypropylene back panel with edge guard.`,
    seat: ["1", "1F", "1B"],
    base: ["A", "R", "W", "S", "TUH"],
    height: ALL,
    casters: ["RC", "HG", "RK"],
    control: ["PM", "T", "FFAC", "E3"],
    footring: ["AFP", "XF"],
    arm: ["3D", "XA"],
    performance: [
      "K",
      "ISO6",
      "ISO5",
      "ISO3",
      "ISO6K",
      "ISO5K",
      "ISO3K",
      "C",
      "06",
      "WD",
      "WDK",
      "ISO8",
      "ISO8C",
      "ISO8K",
      "ISO7",
      "ISO7C",
      "ISO7K",
    ],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["MVMT", "MTCL", "MTHD"],
    price: 492,
  },
  {
    code: "E",
    name: "E - Ergo",
    description: `Upholstered backrest with lumbar support and plastic protective panel; measures 15.5" wide x 14.5" high.`,
    seat: ["A", "B", "E"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["T", "FFAC", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD"],
    footring: ALL,
    arm: ["ARS", "PSAS", "XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["EE"],
    price: 265,
  },
  {
    code: "M",
    name: "M - Large",
    description: `This larger ergonomic backrest is 18" wide x 19" high to deliver added comfort and support for the upper lower back.`,
    seat: ["A", "B", "E"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["T", "FFAC", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD"],
    footring: ALL,
    arm: ["ARS", "PSAS", "XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["AM"],
    price: 286,
  },
  {
    code: "T",
    name: "T - Trapezoid",
    description: `Streamlined upholstered backrest with lumbar support and plastic protective panel; measures 14.5" wide x 9.5" high.`,
    seat: ["A", "B", "E", "R", "VF:R"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["T", "FFAC", "EXE"],
    footring: ALL,
    arm: ["ARS", "PSAS", "XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["BT", "RT"],
    price: 232,
  },
  {
    code: "C",
    name: "C - Sewn Large",
    description: `Roomy backrest with sewn seams upholstery, lumbar support and plastic protective panel; measures 18" wide x 19" high.`,
    seat: ["F", "H"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["T", "FFAC", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD"],
    footring: ALL,
    arm: ["ARS", "PSAS", "XA"],
    performance: ["K", "C", "06"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["FC", "HC"],
    price: 286,
  },
  {
    code: "L",
    name: "L - Sewn Ergo",
    description: `Smaller backrest maximizes elbow room − features sewn seams upholstery, lumbar support and plastic protective panel; measures 15.5" wide x 14.5" high.`,
    seat: ["F", "H"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["T", "FFAC", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD"],
    footring: ALL,
    arm: ["ARS", "PSAS", "XA"],
    performance: ["K", "C", "06"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["FL", "HL"],
    price: 265,
  },
  // {
  //   code: "VF:C",
  //   name: "VF:C - Vacuum Formed Large",
  //   description: `Roomy backrest with vacuum formed vinyl upholstery, lumbar support and plastic protective panel; measures 18" wide x 19" high.`,
  //   seat: ["VF:F", "VF:H"],
  //   base: ["A", "S", "R", "W"],
  //   height: ALL,
  //   casters: ["RC", "HG"],
  //   control: ["T", "FFAC", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD"],
  //   footring: ALL,
  //   arm: ["ARS", "PSAS", "XA"],
  //   performance: ["ISO6", "ISO5", "ISO4", "ISO3", "C", "06", "ISO8", "ISO7", "ISO8C", "ISO7C"],
  //   vinylUnderwrap: ALL,
  //   memoryFoam: ALL,
  //   series: ["VF:FC", "VF:HC"],
  //   price: 307,
  // },
  // {
  //   code: "VF:L",
  //   name: "VF:L - Vacuum Formed Ergo",
  //   description: `Smaller backrest maximizes elbow room − features vacuum formed vinyl upholstery, lumbar support and plastic protective panel; measures 15.5" wide x 14.5" high.`,
  //   seat: ["VF:F", "VF:H"],
  //   base: ["A", "S", "R", "W"],
  //   height: ALL,
  //   casters: ["RC", "HG"],
  //   control: ["T", "FFAC", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD"],
  //   footring: ALL,
  //   arm: ["ARS", "PSAS", "XA"],
  //   performance: ["ISO6", "ISO5", "ISO4", "ISO3", "C", "06", "ISO8", "ISO7", "ISO8C", "ISO7C"],
  //   vinylUnderwrap: ALL,
  //   memoryFoam: ALL,
  //   series: ["VF:FL", "VF:HL"],
  //   price: 233,
  // },
  {
    code: "U",
    name: "U - Urethane",
    description: `Black, self-skinned urethane backrest with integral lumbar support and 0.09375" ventilating ribs; measures 16.5" wide x 12.5" high.`,
    seat: ["U"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["T", "FFAC"],
    footring: ALL,
    arm: ["ARS", "XA"],
    performance: [
      "K",
      "ISO6",
      "ISO6K",
      "C",
      "06",
      "ISO8",
      "ISO8K",
      "ISO7",
      "ISO7K",
      "ISO8C",
      "ISO7C",
    ],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["UU"],
    price: 201,
  },
  {
    code: "S",
    name: "S - Scepter",
    description: `Tall, contoured and molded upholstered backrest with lumber support; measures 20" wide x 23" high.`,
    seat: ["A", "F"],
    base: ["R", "P"],
    height: ["L", "M"],
    casters: ["RC", "HG", "IPR"],
    control: ["EXE", "IP"],
    footring: ["XF"],
    arm: ["PSAS", "ARS", "ISAS", "XA"],
    performance: ["06"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["IP", "Scepter"],
    price: [
      {
        component: "base",
        code: ["P"],
        price: 0,
      },
      {
        component: "base",
        code: ALL,
        price: 602,
      },
    ],
  },
  {
    code: "G",
    name: "G - Armor",
    description: `Polypropylene backrest with lumbar support, UV inhibitor and antimicrobial properties; measures 16" wide x 10.75" high.`,
    seat: ["G"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["T", "FFAC"],
    footring: ALL,
    arm: ["XA"],
    performance: [
      "K",
      "ISO6",
      "C",
      "ISO4",
      "06",
      "ISO6K",
      "ISO4K",
      "ISO8",
      "ISO8K",
      "ISO7",
      "ISO7K",
      "ISO8C",
      "ISO7C",
    ],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["GG"],
    price: 151,
  },
  {
    code: "W",
    name: "W - Plywood",
    description: `Maple 5-ply plywood backrest measures 14.5" wide x 8.75" deep x 0.25" thick.`,
    seat: ["W"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "HG"],
    control: ["T"],
    footring: ALL,
    arm: ["XA"],
    performance: ["06", "C"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["WW"],
    price: 221,
  },
  {
    code: "X",
    name: "No Back",
    description: "",
    seat: ["K", "T", "C", "R", "VF:R", "TX", "L"],
    base: ["A", "S", "R", "W", "G", "C", "A18", "A24", "A30"],
    height: ALL,
    casters: ["RC", "RK", "HG", "GL", "CRC"],
    control: ["S", "P", "FA", "X", "L"],
    footring: ALL,
    arm: ["XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["KX", "2A", "RX", "TX", "CX", "GX", "AJ", "TXG"],
  },
  {
    code: "P",
    name: "P - Mesh",
    description: `Airy, roomy and ergonomic black nylon mesh back with black composite frame, adjustable lumbar support and back height adjustment; measures 19" wide x 24" high.`,
    seat: ["A", "F"],
    base: ["R", "W"],
    height: ["L", "M"],
    casters: ["RC", "HG"],
    control: ["Z"],
    footring: ALL,
    arm: ["ARS", "PSAS", "XA"],
    performance: ["C", "06"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["Z"],
    price: 383,
  },
  {
    code: "B",
    name: "B - Mesh Back",
    description: `Breathable, flexible, woven, black mesh backrest with recycled nylon frame; 16.375" wide x 16" high`,
    seat: ["A", "B", "E"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["T", "FFAC", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD"],
    footring: ALL,
    arm: ["ARS", "PSAS", "XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["EB"],
    upholstery: ["P28605"],
    price: 275,
  },
];
