import React from "react";
import ReactSelect from "react-select";
import styled, { withTheme } from "styled-components";
import { Field } from "formik";
import { compose } from "recompose";
import withFieldProps from "./withFieldProps";
import { FieldWrap, Label, FieldError } from "./Styles";

const StyledReactSelect = styled(ReactSelect)`
  font-size: 1rem;
  margin: 0.5rem 0;
`;

const SelectField = ({
  name,
  label,
  error,
  required,
  field,
  form,
  description,
  options,
  theme,
  ...props
}) => {
  // If options are present, see if we need to transform them to {value, label} object format
  const modifiedOptions =
    options && options.length > 0
      ? options.map((option) => {
          if (typeof option === "string") {
            return {
              value: option,
              label: option,
            };
          }
          const { _id, name: optionName, value, label: optionLabel } = option || {};
          return {
            value: value || _id,
            label: optionLabel || optionName,
          };
        })
      : [];

  // Set the initial value
  let fieldValue;
  if (field.value) {
    const [initialOption] = modifiedOptions.filter(({ value }) => field.value === value) || [];
    if (initialOption) fieldValue = initialOption;
  }

  const { primary } = theme || {};

  const reactSelectStyles = {
    control: (base, state) => {
      if (state.isFocused)
        return {
          ...base,
          borderColor: "transparent",
          boxShadow: `0 0 3px 1px ${primary}`,
          "&:hover": {
            borderColor: "transparent",
          },
        };
      return base;
    },
    option: (base, state) => {
      let backgroundColor = "transparent";
      let color = primary;
      if (state.isFocused) {
        backgroundColor = "#9c64b7";
        color = "#FFFFFF";
      }
      if (state.isSelected) {
        backgroundColor = primary || "#000";
        color = "#FFFFFF";
      }
      return {
        ...base,
        backgroundColor,
        color,
      };
    },
  };

  return (
    <FieldWrap>
      <Label required={required}>{label}</Label>
      <StyledReactSelect
        name={name}
        id={name}
        options={modifiedOptions}
        styles={reactSelectStyles}
        value={fieldValue}
        onChange={({ value }) => form.setFieldValue(name, value)}
        onBlur={() => form.setFieldTouched(name)}
        classNamePrefix="styled-react-select"
        {...props}
      />
      {error ? <FieldError>{error}</FieldError> : null}
    </FieldWrap>
  );
};

const EnhancedSelect = compose(withFieldProps, withTheme)(SelectField);
const Select = (props) => <Field {...props} component={EnhancedSelect} />;
export default Select;
