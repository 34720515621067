/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Flex, Box as OriginalBox } from "grid-styled";
import { display } from "styled-system";
import styled, { css } from "styled-components";
import FA from "@fortawesome/react-fontawesome";
import { faBars, faUndo } from "@fortawesome/fontawesome-free-solid";
import logo from "./BioFit_logo.png";
import catalogDownload from "./full_catalog_download.svg";
import { Container } from "../Grid";
import withToggle from "../withToggle";

const Box = OriginalBox.extend`
  ${display};
`;

const Logo = styled.img`
  height: 75px;
  width: auto;
`;
const Catalog = styled.img`
  height: 110px;
  width: auto;
  margin: 1rem 0;
`;

const Upper = Box.extend`
  background: ${({ theme }) => theme.primary};
  padding: 0.5rem 0;
  @media print {
    display: none;
  }
`;

const Lower = styled.div`
  background: white;
`;

const UpperLink = styled.a`
  color: white;
  font-size: 0.8rem;
`;
const LowerLink = styled.a`
  font-size: 0.8rem;
`;

const HamburgerLink = styled.a`
  padding: 1rem;
  font-size: 1.2rem;
  transition: all 0.3s ease-out;
  cursor: pointer;
  :hover {
    background: #ccc;
  }
  @media print {
    display: none;
  }
`;

const Hamburger = (props) => {
  const { toggleMobileMenuShow } = props;
  return (
    <HamburgerLink onClick={toggleMobileMenuShow}>
      <FA icon={faBars} />
    </HamburgerLink>
  );
};

const MobileMenuWrapper = styled.ul`
  opacity: 0;
  max-height: 0;
  height: auto;
  transition: all 0.3s ease-out;
  padding: 0;
  margin: 0;
  list-style: none;
  display: none;

  ${({ visible }) =>
    visible &&
    css`
      display: block;
      opacity: 1;
      max-height: 200px;
    `};
`;

const StartOver = styled.div`
  font-size: 0.8rem;
  padding-left: 0.5rem;
  @media print {
    display: none;
  }
`;

const LI = styled.li`
  margin: 0.5rem 0 0.5rem 1rem;
`;

const MobileMenu = ({ mobileMenuShow }) => {
  return (
    <MobileMenuWrapper visible={mobileMenuShow}>
      <LI>
        <a target="_blank" href="https://www.biofit.com/resources/catalogs">
          {" "}
          Download Catalogs
        </a>
      </LI>
      <LI>
        <a target="_blank" href="https://www.biofit.com/category/by-type">
          BioFit Seating
        </a>
      </LI>
      <LI>
        <a target="_blank" href="https://www.biofit.com/company/overview">
          About BioFit
        </a>
      </LI>
      <LI>
        <a target="_blank" href="https://www.biofit.com/contact">
          Contact BioFit
        </a>
      </LI>
    </MobileMenuWrapper>
  );
};

const Header = (props) => {
  const { location } = props;
  const { pathname } = location || {};
  return (
    <header>
      <Upper display={["none", "block"]}>
        <Container>
          <Flex justifyContent="flex-end">
            <UpperLink target="_blank" href="https://www.biofit.com/contact">
              Contact BioFit
            </UpperLink>
          </Flex>
        </Container>
      </Upper>
      <Lower>
        <Container>
          <Flex alignItems="center" justifyContent="space-between">
            <Box width={[1, 1 / 3, 1 / 2]}>
              <a target="_blank" href="https://www.biofit.com">
                <Logo src={logo} alt="BioFit" />
              </a>
              {pathname !== "/" && (
                <StartOver>
                  <Link to="/">
                    <FA icon={faUndo} /> start over
                  </Link>
                </StartOver>
              )}
            </Box>
            <Box display={["none", "block"]} width={[1, 2 / 3, 1 / 2]}>
              <Flex alignItems="center" justifyContent="space-between">
                <a target="_blank" href="https://www.biofit.com/resources/catalogs">
                  <Catalog src={catalogDownload} alt="Download Catalogs" />
                </a>
                <LowerLink target="_blank" href="https://www.biofit.com/category/by-type">
                  BioFit Seating
                </LowerLink>
                <LowerLink target="_blank" href="https://www.biofit.com/company/overview">
                  About BioFit
                </LowerLink>
              </Flex>
            </Box>
            <Box display={["block", "none"]}>
              <Hamburger {...props} />
            </Box>
          </Flex>
          <MobileMenu {...props} />
        </Container>
      </Lower>
    </header>
  );
};

export default compose(
  withRouter,
  withToggle({ name: "mobileMenu", trueName: "show", falseName: "hide" })
)(Header);
