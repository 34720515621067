import React, { useState } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import FA from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAngleLeft,
  faCheck,
  faQuestionCircle,
  faExternalLinkAlt,
} from "@fortawesome/fontawesome-free-solid";
import { H3 } from "../TextStyles";
import { Formik } from "formik";
import { RadioGroup } from "../Forms";
import yup from "yup";
import { withProps } from "recompose";
import calculateAvailableOptions from "../../calculateAvailableOptions";
import removeInvalidSelections from "../../removeInvalidSelections";
import { buildManufactureCode, selectFirstValidFromSelection } from "../../manufactureCode";
import getImageNames from "../../getImageNames";
import Overlay from "../Overlay";
import DescriptionList from "../DescriptionList";
import seatComponents from "../../seatComponents";
import notifications from "../notifications";

const { REACT_APP_IMAGES_URL: imageUrl } = process.env;
const visibleOptions = [
  "seat",
  "back",
  "performance",
  "base",
  "height",
  "casters",
  "control",
  "footring",
  "arm",
  "upholstery",
];
const useLowerImage = ["base", "height", "casters", "footring", "performance"];

const OptionHeader = styled(H3)`
  font-weight: 400;
  font-size: 1rem;
  margin: 0 0 1rem 0;
`;

const UpholsteryLink = styled.a`
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 1rem;
`;

const ItemCheck = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  display: ${({ isSelected }) => (isSelected ? "flex" : "none")};
  color: ${({ isSelected }) => !isSelected && "#CCC"};
`;

const ItemStyle = styled.div`
  width: 130px;
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;

  &:hover ${ItemCheck} {
    display: flex;
  }
`;

const ItemImg = styled.div`
  width: 125px;
  height: 125px;
  border: 1px solid#dedede;
  background-image: url(${({ thumb }) => thumb});
  background-size: cover;
  background-position-y: ${({ lower }) => lower && "-75px"};
  position: relative;
`;

const ScrollStyle = styled.div`
  & .react-horizontal-scrolling-menu--wrapper {
    position: relative;
  }
  & .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ScrollGradientStyle = `
position: absolute;
  width: 65px;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  color: #6d6d6d70;
  font-size: 2rem;
  padding-bottom: 1rem;
  cursor: pointer;
`;

const LeftScrollGradient = styled.div`
  ${ScrollGradientStyle}
  background: transparent linear-gradient(90deg, #f2f2f2 0%, #f2f2f200 100%) 0% 0% no-repeat
    padding-box;
`;

const RightScrollGradient = styled.div`
  ${ScrollGradientStyle}
  background: transparent linear-gradient(270deg, #f2f2f2 0%, #f2f2f200 100%) 0% 0% no-repeat
    padding-box;
  right: 0;
  justify-content: flex-end;
`;

const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
  if (isFirstItemVisible) return null;
  return (
    <LeftScrollGradient onClick={() => scrollPrev()}>
      <FA icon={faAngleLeft} />
    </LeftScrollGradient>
  );
};

const RightArrow = () => {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  if (isLastItemVisible) return null;
  return (
    <RightScrollGradient onClick={() => scrollNext()}>
      <FA icon={faAngleRight} />
    </RightScrollGradient>
  );
};

const notifyOnAutoChange = ({ changed, selected }) => {
  changed.forEach(({ key, value }) => {
    const { data, name } = seatComponents.find(({ type }) => type === key);
    const newOption = data.find(({ code }) => code === value);
    const newOptionName = newOption.dynamicName ? newOption.dynamicName(selected) : newOption.name;
    notifications({ warning: `${name} has automatically changed to ${newOptionName}` });
  });
};

const selectOption = ({ history, location, currentSelected, option, item }) => {
  let selected = currentSelected;

  selected[option.type] = item.code;

  const beforeAutoChange = { ...selected };

  selected = removeInvalidSelections(selected, option.type);

  selected = selectFirstValidFromSelection(selected, { forceType: option.type });

  // need to reset this in fringe cases where the component isn't available until running through all component
  // options, and it doesn't actually change what the user selected.
  selected[option.type] = item.code;

  // selected = detectSeries(selected);

  const changed = [];
  Object.entries(selected).forEach(([key, value]) => {
    if (beforeAutoChange[key] !== value) changed.push({ key, value });
  });

  if (changed.length > 0) {
    notifyOnAutoChange({ changed, selected });
  }

  const manufactureCode = buildManufactureCode(selected);
  location.pathname = `/build/${manufactureCode}`;
  // location.search = queryString.stringify({
  //   ...queryString.parse(location.search),
  //   series: selected.series,
  // });
  history.replace(location);
};

const Item = ({ option, item, selected: currentSelected }) => {
  const history = useHistory();
  const location = useLocation();

  let asIfItemWasSelected = { ...currentSelected };
  asIfItemWasSelected[option.type] = item.code;

  asIfItemWasSelected = removeInvalidSelections(asIfItemWasSelected, option.type);

  asIfItemWasSelected = selectFirstValidFromSelection(asIfItemWasSelected, {
    forceType: option.type,
  });

  asIfItemWasSelected[option.type] = item.code;

  const { upper, lower } = getImageNames(asIfItemWasSelected);

  let useLower = useLowerImage.includes(option.type);

  // AJ series stools only have lower images
  if (currentSelected?.series === "AJ" || item?.code === "TX") useLower = true;

  const imageName = useLower ? lower : upper;

  const isSelected = currentSelected[option.type] === item.code;

  return (
    <ItemStyle onClick={() => selectOption({ history, location, currentSelected, option, item })}>
      <ItemImg lower={useLower} thumb={`${imageUrl}${imageName}-000.png`}>
        <ItemCheck isSelected={isSelected}>
          <FA icon={faCheck} />
        </ItemCheck>
      </ItemImg>
      {item.name}
    </ItemStyle>
  );
};

const Option = ({ option, selected, setOpenDescription }) => {
  const { type, name } = option || {};

  if (!visibleOptions.includes(type)) return null;

  const data = option.data.map((d) => ({
    ...d,
    name: d.dynamicName ? d.dynamicName(selected) : d.name,
  }));

  return (
    <div>
      <OptionHeader>
        {name} Options{" "}
        <FA
          style={{ cursor: "pointer" }}
          icon={faQuestionCircle}
          onClick={() => setOpenDescription(type)}
        />
        {selected?.upholstered === "upholstered" && type === "upholstery" && (
          // eslint-disable-next-line react/jsx-no-target-blank
          <UpholsteryLink
            target="_blank"
            href="https://select.cfstinson.com/Finishes/Samples.jsp?lid=6420"
          >
            <FA icon={faExternalLinkAlt} /> View More Options
          </UpholsteryLink>
        )}
      </OptionHeader>

      <ScrollStyle>
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onWheel={onWheel}>
          {data.map((item) => (
            <Item
              key={item.code}
              itemId={item.code}
              option={option}
              item={item}
              selected={selected}
            />
          ))}
        </ScrollMenu>
      </ScrollStyle>
    </div>
  );
};

const simpleSchema = yup.object().shape({
  vinylUnderwrap: yup.string().label("Vinyl Underwrap"),
  memoryFoam: yup.string().label("Memory Foam"),
});

const RadioField = withProps({ schema: simpleSchema })(RadioGroup);

const SimpleOptionsStyle = styled.div`
  background: #dedede;
  padding: 1rem;
  margin-top: 1rem;
`;

const SimpleOptions = ({ selected, options, setOpenDescription }) => {
  const history = useHistory();
  const location = useLocation();

  const showOptions = ["vinylUnderwrap", "memoryFoam"];

  const simpleOptions = options.filter(({ type }) => showOptions.includes(type));

  return (
    <SimpleOptionsStyle>
      <Formik
        initialValues={{
          vinylUnderwrap: selected.vinylUnderwrap,
          memoryFoam: selected.memoryFoam,
        }}
        validationSchema={simpleSchema}
        onSubmit={() => null}
        component={() => (
          <React.Fragment>
            {simpleOptions.map((option) => {
              const opt = option.data.map(({ code, name }) => {
                return { id: code, name };
              });

              return (
                <RadioField
                  key={option.type}
                  name={option.type}
                  options={opt}
                  label={
                    <div>
                      {option.name}{" "}
                      <FA
                        style={{ cursor: "pointer" }}
                        icon={faQuestionCircle}
                        onClick={() => setOpenDescription(option.type)}
                      />
                    </div>
                  }
                  afterChange={(event) => {
                    selectOption({
                      history,
                      location,
                      currentSelected: selected,
                      option,
                      item: { code: event.target.value },
                    });
                  }}
                />
              );
            })}
          </React.Fragment>
        )}
      />
    </SimpleOptionsStyle>
  );
};

const Options = ({ selected }) => {
  const [openDescription, setOpenDescription] = useState();
  const closeDescription = () => setOpenDescription(null);

  // calculate options available within the selected market, style, series, and upholstered choices
  const { market, style, upholstered, series, seat, performance } = selected || {};

  const { options } = calculateAvailableOptions({
    market,
    style,
    upholstered,
    series,
    seat,
    performance,
  });

  return (
    <React.Fragment>
      {options.map((option) => (
        <Option
          key={option.type}
          option={option}
          selected={selected}
          setOpenDescription={setOpenDescription}
        />
      ))}
      <SimpleOptions
        selected={selected}
        options={options}
        setOpenDescription={setOpenDescription}
      />
      {openDescription ? (
        <Overlay onClose={closeDescription}>
          <DescriptionList
            onClose={closeDescription}
            options={options}
            type={openDescription}
            selected={selected}
          />
        </Overlay>
      ) : null}
    </React.Fragment>
  );
};

export default Options;

function onWheel(apiObj, ev) {
  const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isTouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}
