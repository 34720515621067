import React from "react";
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import theme from "./ui/theme";
import Routes from "./ui/Routes.jsx";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";

import { injectGlobal } from "styled-components";
import { PricingProvider } from "./ui/Pricing/context";

injectGlobal`
  body{
    margin: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: ${theme.primary};
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    background-color: white;
    }
    * {box-sizing: border-box;}
    a{
      font-weight: 500;
      color: ${theme.primary};
      text-decoration: none;
    }
    img{
      max-width: 100%;
      height: auto;
    }
`;

const App = () => (
  <ThemeProvider theme={theme}>
    <div>
      <Helmet titleTemplate="%s | BioFit" />
      <NotificationContainer />
      <PricingProvider>
        <Routes />
      </PricingProvider>
    </div>
  </ThemeProvider>
);

export default App;
