const heightDescription = ({ selected, height }) => {
  const { seat, control, base } = selected || {};

  if (["A18", "A24", "A30"].indexOf(base) !== -1) {
    if (height === "L")
      return `For low working heights such as desks or benches approximately 30" or shorter. 18" high.`;
    if (height === "M")
      return `For medium height work surfaces such as workbenches approximately 32" in height. 24" high`;
    if (height === "H")
      return `For high working heights such as standing height benches 40+". 30" high.`;
  }

  let ranges = {
    L: [17, 22],
    M: [19, 26],
    H: [22, 32],
  };

  // Tubular default
  if (base === "S") {
    ranges = {
      L: [18, 22],
      M: [21, 28],
      H: [25, 32],
    };
  }

  if (seat === "1") {
    ranges = {
      L: [17, 21],
      M: [20, 27],
      H: [24, 33.5],
    };
  }

  if ((seat === "1F" || seat === "1B") && (control === "T" || control === "FFAC")) {
    ranges = {
      L: [17, 22],
      M: [19, 26.5],
      H: [21.5, 32],
    };
  }

  if (control === "E3") {
    ranges = {
      L: [17.5, 22.5],
      M: [21, 28.5],
      H: [23.5, 34],
    };
  }

  if ((base === "S" || base === "TUH") && (seat === "1F" || seat === "1B")) {
    ranges = {
      L: [17.5, 21.5],
      M: [20.5, 27.5],
      H: [24.5, 31.5],
    };
  }

  if (["G", "U", "K", "C"].indexOf(seat) !== -1) {
    if (base === "S") {
      ranges = {
        L: [17, 21],
        M: [20, 27],
        H: [24, 31],
      };
    } else {
      ranges = {
        L: [16, 21],
        M: [18, 25],
        H: [21, 31],
      };
    }
  }

  if (seat === "W") {
    if (base === "S") {
      ranges = {
        L: [16, 20],
        M: [19, 26],
        H: [23, 30],
      };
    } else {
      ranges = {
        L: [15, 20],
        M: [17, 24],
        H: [20, 30],
      };
    }
  }

  if (base === "P") {
    ranges = {
      L: [17, 22],
      M: [20, 25],
      H: [22, 32],
    };
  }
  if (base === "C") {
    ranges = {
      L: [18, 23],
      M: [22, 29],
      H: [22, 32],
    };
  }

  if (seat === "T") {
    if (base === "G") {
      ranges = {
        L: [17, 22],
        M: [20, 25],
        H: [22, 32],
      };
    } else if (base === "S") {
      ranges = {
        L: [15, 19],
        M: [18, 25],
        H: [22, 29],
      };
    } else {
      ranges = {
        L: [14, 19],
        M: [16, 23],
        H: [19, 29],
      };
    }
  }

  if (seat === "L") {
    ranges = {
      L: [17, 22],
      M: [22, 29],
      H: [24, 34],
    };
  }

  const lowRange = ranges[height][0];
  const highRange = ranges[height][1];

  if (height === "L")
    return `For low working heights such as desks or benches ${
      highRange + 12
    }" or shorter. Provides a range of ${lowRange}" - ${highRange}".`;
  if (height === "M")
    return `For medium height work surfaces such as workbenches ${lowRange + 12}" to ${
      highRange + 12
    }" high. Provides a range of ${lowRange}" - ${highRange}".`;
  if (height === "H")
    return `For high working heights such as standing height benches measuring ${
      lowRange + 12
    }" up to 40+". Provides a range of ${lowRange}" - ${highRange}".`;
};

export default heightDescription;
