import _ from "lodash";
import React from "react";
import styled from "styled-components";
import { Flex, Box } from "grid-styled";
import FA from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/fontawesome-free-solid";
import seatComponents from "../seatComponents";
import { H2 } from "./TextStyles";

const DescriptionList = styled.div`
  background: white;
  padding: 1rem;
  text-align: left;
`;

const UL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const LI = styled.li`
  border-top: 1px solid #efefef;
  border-left: 1px solid #efefef;
  border-right: 1px solid #efefef;
  padding: 1rem;
  &:last-child {
    border-bottom: 1px solid #efefef;
  }
`;

const Close = styled.span`
  cursor: pointer;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.primary};
  @media print {
    display: none;
  }
`;
const DescriptionListComponent = ({ type, selected, options = seatComponents, onClose }) => {
  let data;

  if (type) {
    const set = options.filter(({ type: typeCompare }) => type === typeCompare)[0] || {};
    ({ data } = set);
  } else if (selected) {
    const selectedCollection = _.map(selected, (code, type) => {
      return { code, type };
    });

    data = selectedCollection.map(({ code, type }) => {
      const set = options.filter(({ type: typeCompare }) => type === typeCompare)[0] || {};
      const { data, name: typeName } = set;
      const { name, description, dynamicDescription, dynamicName } =
        (data || []).filter(({ code: codeCompare }) => code === codeCompare)[0] || {};

      return {
        typeName,
        code,
        name,
        description,
        dynamicDescription,
        dynamicName,
      };
    });
  } else {
    return null;
  }

  data = data.map(({ dynamicDescription, dynamicName, description, name, ...rest }) => ({
    ...rest,
    name: dynamicName ? dynamicName(selected) : name,
    description: dynamicDescription ? dynamicDescription(selected) : description,
  }));

  return (
    <DescriptionList>
      <Flex justifyContent="space-between" alignItems="center">
        <H2>Component Descriptions</H2>
        <Close onClick={onClose}>
          <FA icon={faTimesCircle} />
        </Close>
      </Flex>

      <UL>
        {data.map(({ typeName, code, name, description }) => (
          <LI key={`${typeName}-${code}`}>
            <Flex flexWrap="wrap" alignItems="center">
              {typeName
                ? [
                    <Box key="type" width={[1, 0.15]}>
                      <strong>{typeName}</strong>
                    </Box>,
                    <Box key="name" width={[1, 0.25]}>
                      {name}
                    </Box>,
                    <Box key="description" width={[1, 0.6]}>
                      {description}
                    </Box>,
                  ]
                : [
                    <Box key="name" width={[1, 1 / 4]}>
                      <strong>{name}</strong>
                    </Box>,
                    <Box key="description" width={[1, 3 / 4]}>
                      {description}
                    </Box>,
                  ]}
            </Flex>
          </LI>
        ))}
      </UL>
    </DescriptionList>
  );
};
export default DescriptionListComponent;
