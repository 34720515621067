import styled, { css } from "styled-components";

const common = css`
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Button = styled.button`
  ${common};
  background: white;
  border: 1px solid ${({ theme }) => theme.primary};
  padding: 0.5rem;
  margin: 0.5rem;
  font-size: 1rem;
  min-width: 3rem;
  color: ${({ theme }) => theme.primary};
  &:hover {
    background: ${({ theme }) => theme.primary};
    color: white;
  }
  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.primary};
      color: white;
    `};
`;

export default Button;

export const LargeButton = styled.button`
  ${common};
  background: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.primary};
  padding: 0.75rem;
  font-size: 1.2rem;
  color: white;
  width: 100%;
  &:hover {
    opacity: 0.8;
  }
`;
