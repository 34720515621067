/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Flex } from "grid-styled";
import { getVideosForManufactureCode } from "../../videos";
import { H2 } from "../TextStyles";
import { withState } from "recompose";
import Overlay from "../Overlay";

const VideoGrid = ({ videos, title, setYoutubeId }) => {
  if (!videos || videos.length === 0) return null;
  return (
    <div>
      <H2>{title}</H2>
      <Flex flexWrap="wrap">
        {videos.map(({ youtubeId, title: videoTitle }) => {
          return (
            <Flex
              key={youtubeId}
              justifyContent="center"
              my={3}
              px={[2, 3]}
              width={[1, 1 / 2, 1 / 3, 1 / 4]}
            >
              <a onClick={() => setYoutubeId(youtubeId)}>
                <img alt={videoTitle} src={`https://img.youtube.com/vi/${youtubeId}/0.jpg`} />
                <div>{videoTitle}</div>
              </a>
            </Flex>
          );
        })}
      </Flex>
    </div>
  );
};

const Videos = ({ manufactureCode, youtubeId, setYoutubeId }) => {
  const { assembly, operational } = getVideosForManufactureCode(manufactureCode);

  return (
    <React.Fragment>
      <VideoGrid videos={operational} title="Operational Videos" setYoutubeId={setYoutubeId} />
      <VideoGrid videos={assembly} title="Assembly Videos" setYoutubeId={setYoutubeId} />
      {youtubeId && (
        <Overlay onClose={() => setYoutubeId(null)}>
          <iframe
            id="ytplayer"
            title="ytplayer"
            type="text/html"
            width="640"
            height="360"
            src={`https://youtube.com/embed/${youtubeId}?autoplay=1`}
            frameBorder="0"
          />
        </Overlay>
      )}
    </React.Fragment>
  );
};

export default withState("youtubeId", "setYoutubeId", null)(Videos);
