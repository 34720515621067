import React from "react";
import { Flex, Box } from "grid-styled";
import { Link } from "react-router-dom";
import FA from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/fontawesome-free-solid";
import moment from "moment";
import { Helmet } from "react-helmet";
import { PageContainer } from "../Grid";
import { H1, H2 } from "../TextStyles";
import { LargeButton } from "../Button";
import chairUrlString from "../chairUrlString";
import ChairImage from "../ChairImage";
import Videos from "./Videos";
import { buildSelectedFromCode } from "../../manufactureCode";

const Warranty = ({ match }) => {
  const { manufactureDate } = match.params;
  if (!manufactureDate) return null;

  const d = moment(manufactureDate, "MM-DD-YYYY");
  if (!d.isBefore()) return null;

  const expires = moment(d).add(13, "years");

  const warrantyLeft = () => {
    if (expires.isBefore()) return false;

    const now = moment();
    const years = expires.diff(now, "year");
    now.add(years, "years");

    const months = expires.diff(now, "months");
    now.add(months, "months");

    const days = expires.diff(now, "days");

    const plural = (num) => {
      if (num === 1) return "";
      return "s";
    };
    let left = "";
    if (years > 0) left += `${years} year${plural(years)}, `;
    if (months > 0) left += `${months} month${plural(months)}, `;
    left += `${days} day${plural(days)}`;
    return left;
  };

  const warrantyString = warrantyLeft();

  return (
    <div>
      <H2>Warranty Information</H2>
      <p> Your chair was manufactured on {d.format("MMM Do, YYYY")}</p>
      {warrantyString
        ? `You have ${warrantyString} left on your 13 year warranty`
        : "Your 13 year warranty has expired"}
      {warrantyString ? (
        <p>
          <Link to={`/warranty-request${chairUrlString(match.params)}`}>
            Submit a Warranty Issue
          </Link>
        </p>
      ) : null}
    </div>
  );
};

const WarrantyOrderWrap = Box.extend`
  min-height: 400px;
`;

class ChairDetails extends React.Component {
  componentDidMount() {
    const { match } = this.props;
    const { manufactureCode, invoiceNumber, manufactureDate } = match.params;

    // Set these details in session storage so we can get back to this profile
    sessionStorage.setItem(
      "chairProfile",
      JSON.stringify({ manufactureCode, invoiceNumber, manufactureDate })
    );
  }

  render() {
    const { match } = this.props;
    const { manufactureCode } = match.params;

    return (
      <PageContainer>
        <Helmet>
          <title>My Chair Profile</title>
        </Helmet>
        <H1>Your BioFit Chair Profile</H1>
        <p>
          This resource allows you to learn about your BioFit chair, and verify the status of your
          13-year warranty. Check out the videos below for step-by-step assembly and adjustment
          instructions, to ensure you’re getting the most out of your BioFit seating.
        </p>
        <p>
          Your BioFit chair has manufacture code <strong>{manufactureCode}</strong>. See below for
          more helpful information, and please{" "}
          <a href="https://www.biofit.com/contact">contact BioFit</a> with any questions!
        </p>
        <Flex flexWrap="wrap">
          <WarrantyOrderWrap width={[1, 1 / 2, 2 / 3]}>
            <Flex justifyContent="center">
              <ChairImage selected={buildSelectedFromCode(manufactureCode)} maxHeight="600px" />
            </Flex>
          </WarrantyOrderWrap>
          <WarrantyOrderWrap width={[1, 1 / 2, 1 / 3]}>
            <Warranty match={match} />

            <Link to={`/build/${manufactureCode}`}>
              <LargeButton active type="submit">
                <FA icon={faShoppingCart} /> Order More
              </LargeButton>
            </Link>
            <div style={{ marginTop: "1rem", textAlign: "center" }}>
              Customize this chair and reorder
            </div>
          </WarrantyOrderWrap>
        </Flex>
        <Videos manufactureCode={manufactureCode} />
      </PageContainer>
    );
  }
}
export default ChairDetails;
