import React from "react";
import styled from "styled-components";
import { Flex, Box } from "grid-styled";
import withToggle from "../withToggle";
import FA from "@fortawesome/react-fontawesome";
import {
  faStepBackward,
  faPrint,
  faShare,
  faUndo,
  faRedo,
} from "@fortawesome/fontawesome-free-solid";

const ControlStyle = styled.div`
  padding: 0.5rem;
  color: #fff;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
  text-align: center;
  cursor: pointer;
`;

const Control = ControlStyle.extend`
  background: #6d6e70;
`.withComponent(({ className, children, onClick }) => (
  <Box onClick={onClick} width={[1, 49 / 100]} className={className}>
    {children}
  </Box>
)); // Filters out non DOM elements

const RotateControl = ControlStyle.extend`
  background: ${({ theme }) => theme.primary};
  margin: 0 0.2rem;
`;

const ShareStyle = styled.div`
  color: ${({ theme }) => theme.primary};
  text-align: center;
  font-size: 0.9rem;
`;

const ShareInput = styled.input`
  width: 100%;
  padding: 0.25rem;
  margin: 0.5rem 0;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.primary};
`;

const Share = () => (
  <ShareStyle>
    Copy and paste this URL to share this chair.
    <ShareInput
      onFocus={(e) => {
        e.target.setSelectionRange(0, 9999);
      }}
      type="text"
      value={window.location}
      readOnly
    />
  </ShareStyle>
);

const Controls = ({ history, shareShow, toggleShareShow, turnLeft, turnRight }) => {
  return (
    <div>
      <Flex mt="1rem" mb="1rem" flexWrap="wrap" alignItems="center" justifyContent="space-between">
        <Control onClick={history.goBack}>
          <FA icon={faStepBackward} /> Undo
        </Control>
        <Control onClick={() => history.push("/market")}>
          <FA icon={faUndo} /> Reset
        </Control>
        <Control onClick={window.print}>
          <FA icon={faPrint} /> Print Spec Sheet
        </Control>
        <Control onClick={toggleShareShow}>
          <FA icon={faShare} /> Share
        </Control>
      </Flex>
      {shareShow ? <Share /> : null}

      <Flex mt="1rem" justifyContent="center">
        <RotateControl onClick={turnLeft}>
          <FA icon={faRedo} />
        </RotateControl>
        <RotateControl onClick={turnRight}>
          <FA icon={faUndo} />
        </RotateControl>
      </Flex>
      <ShareStyle>Rotate Chair</ShareStyle>
    </div>
  );
};

export default withToggle({ name: "share", trueName: "show", falseName: "hide" })(Controls);
