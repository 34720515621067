import React from "react";
import { FieldArray } from "formik";
import styled from "styled-components";
import withFieldProps from "./withFieldProps";
import { FieldWrap, Label, FieldError } from "./Styles";

const CheckboxGroupWrapper = styled.div`
  margin: 0.5rem 0;
`;

const CheckboxGroup = (props) => {
  const { required, label, options = [], name, values, push, remove, error } = props;
  return (
    <FieldWrap>
      {label ? <Label required={required}>{label}</Label> : null}
      <CheckboxGroupWrapper>
        {options.map((option) => {
          const id = option._id || option.id || option;
          return (
            <div key={id}>
              <label htmlFor={id}>
                <input
                  name={name}
                  type="checkbox"
                  value={id}
                  id={id}
                  checked={!!values[name] && values[name].includes(id)}
                  onChange={(e) => {
                    if (e.target.checked) push(id);
                    else {
                      const idx = values[name].indexOf(id);
                      remove(idx);
                    }
                  }}
                />{" "}
                {option.name || option}
              </label>
            </div>
          );
        })}
      </CheckboxGroupWrapper>
      {error ? <FieldError>{error}</FieldError> : null}
    </FieldWrap>
  );
};

const Enhanced = withFieldProps(CheckboxGroup);
const EnhancedFieldArray = (props) => (
  <FieldArray {...props} render={(faProps) => <Enhanced {...props} {...faProps} />} />
);
export default EnhancedFieldArray;
