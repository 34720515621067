import React from "react";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import FA from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/fontawesome-free-solid";

const crumbs = [
  {
    label: "Choose Market",
    path: "/market",
    visiblePaths: ["/market", "/style", "/upholstery", "/series", "/build"],
  },
  {
    label: "Choose Style",
    path: "/style",
    visiblePaths: ["/style", "/upholstery", "/series", "/build"],
  },
  {
    label: "Choose Upholstery",
    path: "/upholstery",
    visiblePaths: ["/upholstery", "/series", "/build"],
  },
  { label: "Choose Series", path: "/series", visiblePaths: ["/series", "/build"] },
  { label: "Build", path: "/build", visiblePaths: ["/build"] },
];

const BreadcrumbLink = styled(Link)`
  color: #6d6e70;
  font-weight: ${({ active }) => (active ? "600" : "400")};
  margin-right: 0.35rem;
`;

const BreadcrumbStyle = styled.div`
  @media print {
    display: none;
  }
`;

const Crumb = ({ label, path, isLast }) => {
  const location = useLocation();

  const active = location.pathname.includes(path);

  return (
    <BreadcrumbLink to={{ pathname: path, state: location.state }} active={active}>
      {label} {!isLast && <FA icon={faAngleRight} />}
    </BreadcrumbLink>
  );
};
const Breadcrumb = () => {
  const location = useLocation();

  const locationState = location.state || {};

  const secondSlashIndex = location.pathname.indexOf("/", 1);
  const endIndex = secondSlashIndex > 0 ? secondSlashIndex : undefined;
  const pathStart = location.pathname.substring(0, endIndex);

  const visibleCrumbs = crumbs.filter(({ visiblePaths, path }) => {
    if (!visiblePaths.includes(pathStart)) return false;
    if (path === "/upholstery" && locationState.style !== "foundation") return false;
    return true;
  });

  return (
    <BreadcrumbStyle>
      {visibleCrumbs.map(({ label, path }, i) => (
        <Crumb key={path} label={label} path={path} isLast={i + 1 === visibleCrumbs.length} />
      ))}
    </BreadcrumbStyle>
  );
};

export default Breadcrumb;
