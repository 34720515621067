const mvmtBaseHeight = ({ selected, options }) => {
  let filteredOptions = options;
  // RULE: When MVMT seat 1, 1F, or 1B and height M or H, do not allow A or R base

  const { seat, height } = selected || {};

  if (["1", "1F", "1B"].indexOf(seat) !== -1 && ["M", "H"].indexOf(height) !== -1) {
    filteredOptions = filteredOptions.map(({ type, data, ...rest }) => {
      // don't allow A or R base
      if (type === "base") {
        data = data.filter(({ code }) => ["A", "R"].indexOf(code) === -1);
      }
      return {
        type,
        data,
        ...rest,
      };
    });
  }

  return { selected, options: filteredOptions };
};

export default mvmtBaseHeight;
