import React from "react";
import { Formik, Form } from "formik";
import { Flex, Box } from "grid-styled";
import FA from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/fontawesome-free-solid";
import { withProps } from "recompose";
import { Helmet } from "react-helmet";
import { PageContainer } from "../Grid";
import { CheckboxGroup } from "../Forms";
import { H1 } from "../TextStyles";
import { LargeButton } from "../Button";
import { selectedPartsSchema as schema } from "./schema";
import { FormLabel, WarrantyImg, ImgDisclaimer } from "./styles";
import warrantyChairImg from "./biofit_warranty_chair.svg";
import { buildSelectedFromCode } from "../../manufactureCode";

const CheckboxField = withProps({ schema })(CheckboxGroup);

const getControlOptions = (components) => {
  const { control } = components || {};
  const options = [
    ...(control === "PM"
      ? [
          "Front underseat lever (seat tilt)",
          "Forward right handle (seat height / microtension)",
          "Rear right handle (back forward adjustment)",
          "Left front handle (control lock-out)",
        ]
      : []),
    ...(["T", "FFAC"].indexOf(control) !== -1
      ? ["Forward right handle (seat height)", "Rear right handle (seat angle lock)"]
      : []),
    ...(["FFAC", "E3"].indexOf(control) !== -1 ? ["Left handle (backrest adjustment)"] : []),
    ...(control === "EXE" ? ["Backrest knob", "Control"] : []),
    ...(["T:SLD", "FFAC:SLD", "EXE:SLD"].indexOf(control) !== -1
      ? ["Backrest knob", "Control", "Seat Depth Mechanism"]
      : []),
    ...(control === "FA" ? ["Activation ring"] : []),
    ...(["S", "Z", "P", "L", "IP"].indexOf(control) !== -1 ? ["Control"] : []),
  ];
  return options;
};

const StartForm = (props) => {
  const { components } = props;
  const { footring, arms } = components || {};
  return (
    <Form>
      <Box mb="1rem">
        <FormLabel>Backrest</FormLabel>
        <CheckboxField name="selectedParts" options={["Backrest", "Back Bar"]} label={false} />

        {arms !== "XA" && (
          <React.Fragment>
            <FormLabel>Arms</FormLabel>
            <CheckboxField
              name="selectedParts"
              options={[
                "Armrest - Left",
                "Armrest - Right",
                "Armrest Pad - Left",
                "Armrest Pad - Right",
                "Armrest attaching screws",
              ]}
              label={false}
            />
          </React.Fragment>
        )}

        <FormLabel>Seat</FormLabel>
        <CheckboxField name="selectedParts" options={["Seat"]} label={false} />

        <FormLabel>Controls</FormLabel>
        <CheckboxField name="selectedParts" options={getControlOptions(components)} label={false} />

        <FormLabel>Pneumatic</FormLabel>
        <CheckboxField name="selectedParts" options={["Pneumatic Post"]} label={false} />

        {footring !== "XF" && (
          <React.Fragment>
            <FormLabel>Footring</FormLabel>
            <CheckboxField
              name="selectedParts"
              options={["Footring", "Locking Bushing"]}
              label={false}
            />
          </React.Fragment>
        )}

        <FormLabel>Base</FormLabel>
        <CheckboxField name="selectedParts" options={["Base"]} label={false} />

        <FormLabel>Casters / Glides</FormLabel>
        <CheckboxField name="selectedParts" options={["Casters / Glides"]} label={false} />
      </Box>

      <LargeButton active type="submit">
        Continue <FA icon={faAngleRight} />
      </LargeButton>
    </Form>
  );
};

const WarrantyRequest = ({ match, history, location }) => {
  const { params } = match;
  const { manufactureCode, manufactureDate, invoiceNumber } = params || {};
  const components = buildSelectedFromCode(manufactureCode);
  console.log("components :>> ", components);

  return (
    <PageContainer>
      <Helmet>
        <title>Warranty Issue</title>
      </Helmet>
      <H1>Submit Warranty Issue</H1>
      <div>
        BioFit backs backs all of our seating with a 13-year warranty. Simply check the boxes below
        that correspond with your issue, and a BioFit representative will be in touch with you soon.
      </div>
      <Flex flexWrap="wrap">
        <Flex flexWrap="wrap" justifyContent="center" width={[1, 1, 1 / 2]}>
          <WarrantyImg src={warrantyChairImg} />
          <ImgDisclaimer>
            Chair shown for general reference of parts only <br /> and does not reflect your custom
            chair.
          </ImgDisclaimer>
        </Flex>
        <Box width={[1, 1, 1 / 2]}>
          <Formik
            initialValues={{
              selectedParts: [],
            }}
            validationSchema={schema}
            onSubmit={({ selectedParts }) => {
              const partsEncoded = btoa(JSON.stringify(selectedParts));
              history.push({
                ...location,
                pathname: `/warranty-request-submit/${manufactureCode}/${invoiceNumber || ""}/${
                  manufactureDate || ""
                }`,
                search: `?selectedParts=${partsEncoded}`,
              });
            }}
            component={(props) => <StartForm {...props} components={components} />}
          />
        </Box>
      </Flex>
    </PageContainer>
  );
};

export default WarrantyRequest;
