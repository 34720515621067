import React from "react";
import { Link } from "react-router-dom";
import { Flex, Box } from "grid-styled";
import styled from "styled-components";
import FA from "@fortawesome/react-fontawesome";
import { faSearch, faCog } from "@fortawesome/fontawesome-free-solid";
import { Helmet } from "react-helmet";
import { PageContainer } from "../Grid";
import { H1 } from "../TextStyles";
import { LargeButton } from "../Button";
import homeBg from "./biofit_home_builder.png";
import homeBg2 from "./biofit_home_builder@2x.png";

const LinkDescription = styled.div`
  text-align: center;
  margin: 1rem 0;
`;

export const HomePage = styled.div`
  background-image: url(${homeBg});
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: center bottom;
  min-height: 800px;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url(${homeBg2});
  }

  @media (min-width: 52rem) {
    background-position: 107% 100%;
    min-height: 600px;
    background-size: 65%;
  }
  @media (min-width: 64rem) {
    min-height: 650px;
    background-size: 675px;
  }
  @media (min-width: 80rem) {
    background-size: 750px;
  }
  @media (min-width: 92rem) {
    background-position: 87% 100%;
  }
`;

const Home = () => (
  <HomePage>
    <PageContainer>
      <Helmet>
        <title>My Chair</title>
      </Helmet>
      <H1>Discover the comfort and quality of BioFit.</H1>
      <Flex flexWrap="wrap">
        <Box width={[1, 1, 1 / 3]}>
          <Box mb="1rem">
            Get fast, easy access to all the information you need on any chair in the extensive line
            of BioFit furnishings. Or, design your ideal seating option, built to your exact
            specifications.
          </Box>
          <Link to="/find-chair">
            <LargeButton>
              <FA icon={faSearch} /> Find My Chair
            </LargeButton>
          </Link>
          <LinkDescription>
            Get assembly and operational videos, plus up-to-date warranty information.
          </LinkDescription>
          <Link to="/market">
            <LargeButton>
              <FA icon={faCog} /> Build A Chair
            </LargeButton>
          </Link>
          <LinkDescription>
            BioFit can help you customize seating that’s just right for your specifications.
          </LinkDescription>
        </Box>
      </Flex>
    </PageContainer>
  </HomePage>
);

export default Home;
