import _ from "lodash";
import seatComponents from "./seatComponents";
import compoundRestrictions from "./compoundRestrictions";

const availableOptions = (initialSelected, config = {}) => {
  let selected = initialSelected;
  const cfg = {
    forceType: null,
    includeAll: [], // list of optional components to always include as available.
    // Used to always show performance package and shipping options no matter the other options.
    ...config,
  };
  let options = seatComponents;

  const selectedCollection = _.map(selected, (code, type) => {
    return { code, type };
  });

  selectedCollection.forEach(({ code, type }) => {
    if (!code || code === "�") return;
    const dataForSelected = seatComponents.filter(({ type: typeCompare }) => type === typeCompare);
    const [{ data: setForSelected }] = dataForSelected;
    const availableOptionsForSelected =
      setForSelected.filter(({ code: codeCompare }) => code === codeCompare)[0] || {};

    options = options.map(({ type: optionType, data, ...rest }) => {
      const availableCodes = availableOptionsForSelected[optionType] || [];

      let filteredData;

      // if (cfg.forceType === type) return { type: optionType, data, ...rest };

      if (type === optionType || cfg.includeAll.indexOf(optionType) !== -1) {
        filteredData = data;
      } else {
        filteredData = data.filter(
          ({ code: codeCompare }) => availableCodes.indexOf(codeCompare) !== -1
        );
      }

      return { type: optionType, data: filteredData, ...rest };
    });
  });

  // pass the options through a function which handles any compound logic
  ({ selected = {}, options = [] } =
    compoundRestrictions({ selected, options, forceType: cfg.forceType }) || {});
  return { selected, options };
};

export default availableOptions;
