import React from "react";
import { Flex } from "grid-styled";
import styled from "styled-components";
import onClickOutside from "react-onclickoutside";

const Overlay = Flex.extend`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 999;
`;

class OverlayContentClass extends React.Component {
  handleClickOutside() {
    this.props.onClose();
  }

  render() {
    return this.props.children;
  }
}

const StyledContent = styled.div`
  margin: 2rem;
  overflow-y: scroll;
  max-height: 95%;
`;

const OverlayContent = onClickOutside(OverlayContentClass);

const OverlayComponent = (props) => (
  <Overlay justifyContent="center" alignItems="center">
    <OverlayContent {...props}>
      <StyledContent>{props.children}</StyledContent>
    </OverlayContent>
  </Overlay>
);

export default OverlayComponent;
