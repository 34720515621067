export default [
  {
    code: "MVMT",
    name: "MVMT",
    seat: ["1", "1F", "1B"],
    series: ["MVMT", "MTCL", "MTHD"],
  },
  {
    code: "foundation",
    name: "Foundation",
    seat: ["A", "B", "E", "F", "H", "R", "VF:R", "U", "K", "G", "W", "T", "C", "TX", "L"],
    series: [
      "2A",
      "AJ",
      "AM",
      "BT",
      "CX",
      "EE",
      "EB",
      "FC",
      "FL",
      "GG",
      "GX",
      "HC",
      "HL",
      "IP",
      "KX",
      "RT",
      "RX",
      "Scepter",
      "TX",
      "TXG",
      "UU",
      "WW",
      "X",
      "Z",
    ],
  },
];
