import ALL from "./ALL";

export default [
  {
    code: "3D",
    name: "3D - MVMT Arms",
    description: `Cast aluminum arm upright with arm adjustment mechanism and 3.5" wide x 9" long x 7/8" thick
      urethane arm pad. Arm pad can be adjusted in three dimensions: 3.5" vertically, 2" front-to-back, and 1"
      left-to-right for a total armrest width adjustment of 2".`,
    seat: ["1", "1F", "1B"],
    back: ["MB", "TB"],
    base: ["A", "W", "R", "S", "TUH"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["PM", "T", "FFAC", "E3"],
    footring: ["AFP", "XF"],
    performance: [
      "K",
      "ISO6",
      "ISO5",
      "ISO3",
      "ISO6K",
      "ISO5K",
      "ISO3K",
      "C",
      "06",
      "WD",
      "WDK",
      "ISO8",
      "ISO8K",
      "ISO7",
      "ISO7K",
      "ISO8C",
      "ISO7C",
    ],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    price: [
      {
        component: "style",
        code: ["MVMT"],
        price: 389,
      },
    ],
  },
  {
    code: "ARS",
    name: "ARS",
    description: `Height and width adjustable armrests with black self-skinned urethane pads.`,
    seat: ["A", "B", "E", "F", "H", "U"],
    back: ["E", "M", "T", "C", "L", "U", "P", "S", "B"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "HG", "RK"],
    control: ["T", "FFAC", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD", "Z"],
    footring: ALL,
    performance: [
      "K",
      "ISO6",
      "ISO5",
      "ISO6K",
      "ISO5K",
      "C",
      "06",
      "ISO8",
      "ISO8C",
      "ISO8K",
      "ISO7",
      "ISO7C",
      "ISO7K",
    ],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    price: [
      {
        component: "series",
        code: ["UU"],
        price: 212,
      },
      {
        component: "series",
        code: ALL,
        price: 157,
      },
    ],
  },
  {
    code: "PSAS",
    name: "PSAS",
    description: `Height-adjustable armrests with black self-skinned urethane pads.`,
    seat: ["A", "B", "E", "F", "H"],
    back: ["E", "M", "T", "C", "L", "P", "S", "B"],
    base: ["A", "S", "R", "W"],
    height: ALL,
    casters: ["RC", "HG", "RK"],
    control: ["T", "FFAC", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD", "Z"],
    footring: ALL,
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    price: 131,
  },
  {
    code: "XA",
    name: "No Arms",
    description: "",
    seat: ALL,
    back: ALL,
    base: ALL,
    height: ALL,
    casters: ALL,
    control: ALL,
    footring: ALL,
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
  },
  {
    code: "ISAS",
    name: "ISAS",
    description: `Height-adjustable armrests with black self-skinned urethane pads. Available only on Intensive Plus models.`,
    seat: ["F"],
    back: ["S"],
    base: ["P"],
    height: ["M"],
    casters: ["HG", "IPR"],
    control: ["IP"],
    footring: ["XF"],
    performance: ["06"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ALL,
    price: 166,
  },
];
